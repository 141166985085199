<template>
  <v-container fluid>
    <TableSkeletonLoader v-if="loadingTeams"/>
    <TeamsTable :teams="allTeams" v-else/>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TableSkeletonLoader from '@components/common/loaders/TableSkeletonLoader.vue';
import TeamsTable from '@components/company/TeamsTable.vue';

export default {
  name: 'Teams',
  props: ['cid'],
  data: () => ({
    teams: [],
  }),
  components: {
    TableSkeletonLoader,
    TeamsTable,
  },
  methods: {
    ...mapActions(['getTeams']),
  },
  computed: {
    ...mapGetters(['allTeams', 'currentCompany', 'loadingTeams']),
  },
  created() {
    this.getTeams(`company_id=${this.cid}`);
  },
};
</script>
