const links = {
  guest: [
    {
      title: 'Home',
      icon: 'home',
      path: '/',
      id: 'navbar-home-btn',
    },
    {
      title: 'Login',
      icon: 'touch_app',
      path: '/login',
      id: 'navbar-login-btn',
    },
  ],
  auth: [
    {
      title: 'Settings',
      icon: 'account_box',
      path: '/settings/user/:uid/profile',
      id: 'navbar-settings-btn',
    },
    {
      title: 'Logout',
      icon: 'toggle_off',
      path: '/',
      id: 'navbar-logout-btn',
    },
  ],
  side: [
    {
      title: 'Projects',
      icon: 'assignment',
      path: '/projects/view/grid',
      id: 'projects-tab',
    },
    {
      title: 'Analytics',
      icon: 'bar_chart',
      path: '/analytics',
      children: [
        {
          title: 'Insights',
          path: '/analytics',
          id: 'insights-tab',
        },
        {
          title: 'Settings',
          path: '/analytics/settings/permissions',
          id: 'analytics-settings-tab',
        },
      ],
      id: 'analytics-tab',
    },
    {
      title: 'Billing',
      icon: 'attach_money',
      children: [
        {
          title: 'Overview',
          path: '/billing/overview',
          props: true,
          id: 'billing-overview-tab',
        },
        {
          title: 'Subscriptions',
          path: '/billing/subscription-plans/access',
          id: 'billing-subscriptions-tab',
        },
        {
          title: 'Reports',
          path: '/billing/reports',
          id: 'billing-reports-tab',
        },
        {
          title: 'Payment Methods',
          path: '/billing/payment_methods',
          id: 'billing-payment-methods-tab',
        },
        {
          title: 'Invoices',
          path: '/billing/invoices',
          id: 'billing-invoices-tab',
        },
      ],
      id: 'billing-tab',
    },
    {
      title: 'Company',
      icon: 'location_city',
      path: '',
      id: 'company-tab',
    },
    {
      title: 'Help',
      icon: 'help',
      path: '/help/report',
      id: 'reports-tab',
    },
    {
      title: 'Admin',
      icon: 'admin_panel_settings',
      path: '/admin/instances',
      id: 'admin-tab',
    },
  ],
};

export default links;
