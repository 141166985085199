<template>
  <v-container fluid id="severities">
    <Chip
      :text="s"
      :color="severityChipColor(s)"
      size="x-small"
      v-if="!editingImage"
    />
    <v-select
      :items="severities"
      label="Select a severity level"
      v-model="s"
      dense
      outlined
      v-if="editingImage"
    >
    </v-select>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Chip from '@components/common/Chip.vue';
import { severityColorPicker } from '@methods/helpers';

export default {
  name: 'ImageSeverity',
  components: {
    Chip,
  },
  data: () => ({
    edit: false,
    severities: ['None', 'Low', 'Medium', 'High'],
    s: '',
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setModifiedImage']),
    severityChipColor: (severity) => severityColorPicker(severity),
  },
  computed: {
    ...mapGetters(['currentImage', 'editingImage']),
  },
  watch: {
    s(newSeverity) {
      this.setModifiedImage({ type: 'severity', value: newSeverity });
    },
    currentImage(newImage) {
      const newProcessedImage = newImage.process_tracking[newImage.process_tracking.length - 1];
      this.s = newProcessedImage.severity;
    },
  },
  mounted() {
    this.s = this.currentImage
      .process_tracking[this.currentImage.process_tracking.length - 1].severity;
  },
};
</script>
