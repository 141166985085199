<template>
  <v-container fluid>
    <!--- L O A D I N G --->
    <v-row v-if="loadingImages">
      <v-col :cols="(isMobile) ? '12' : '6'">
        <div class="pa-2" v-if="loadingImages">
          <SkeletonLoader type="image"/>
        </div>
      </v-col>
      <v-col :cols="(isMobile) ? '12' : '6'">
        <div class="pa-2" v-if="loadingImages">
          <SkeletonLoader type="image" />
          </div>
      </v-col>
    </v-row>

    <!--- D I S P L A Y --->
    <v-row v-else>
      <v-col :cols="(isMobile) ? '12' : '6'">
        <PieChartV2
          v-if="companyHas('poleByFolders')"
          chartId="priorities"
          title="Structure Priorities"
          :labels="priorities"
        />
        <PieChartV2
          v-else
          chartId="severities"
          title="Severity"
          :labels="severities"
        />
      </v-col>
      <v-col :cols="(isMobile) ? '12' : '6'">
        <PieChartV2 chartId="faults" title="Fault Types" :labels="labels" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import lodash from 'lodash';
import PieChartV2 from '@components/charts/PieChartV2.vue';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';

export default {
  name: 'DashboardCharts',
  props: ['images'],
  components: {
    PieChartV2,
    SkeletonLoader,
  },
  methods: {
    getLabels() {

    },
  },
  computed: {
    ...mapGetters(['allFolders', 'companyHas', 'small', 'extraSmall', 'isLaptop', 'loadingImages']),
    labels() {
      const labelCount = {};
      const processedData = this.images.map((image) => image.process_tracking.slice(-1)[0]);

      const labels = processedData.filter((data) => data.labels);
      const labelList = lodash.flatten(labels.map((data) => {
        const currentLabels = data.labels;
        return currentLabels.map((label) => {
          if (typeof label === 'object') {
            return label.label;
          }
          if (label.includes(':')) return label.split(':')[1];
          return label;
        });
      }));

      labelList.forEach((label) => {
        if (!(label in labelCount)) {
          labelCount[label] = 1;
        } else {
          labelCount[label] += 1;
        }
      });
      return labelCount;
    },
    severities() {
      const severityCount = {};
      const severities = this.images.map((image) => image.process_tracking.slice(-1)[0].severity);

      severities.forEach((severity) => {
        const currentSeverity = (severity === '') ? 'N/A' : severity;
        if (!(currentSeverity in severityCount)) {
          severityCount[currentSeverity] = 1;
        } else {
          severityCount[currentSeverity] += 1;
        }
      });

      return severityCount;
    },
    priorities() {
      if (!this.companyHas('poleByFolders')) return {};

      const priorityCounter = {};

      this.allFolders.forEach((folder) => {
        const priority = (folder.overall_severity === '') ? 'N/A' : folder.overall_severity;

        if (!(folder.overall_severity in priorityCounter)) {
          priorityCounter[priority] = 1;
        } else {
          priorityCounter[priority] += 1;
        }
      });

      return priorityCounter;
    },
    isMobile() {
      return (this.small || this.extraSmall) || !this.isLaptop;
    },
  },
};
</script>
