/*
  |---------|--------------------- | --------------------- |
  | #90A4AE | blue-grey lighten-2  | ''                    |
  | ------- | -------------------- | --------------------- |
  | #66BB6A | green lighten-1      | None                  |
  | ------- |--------------------- | --------------------- |
  | #00B0FF | light-blue accent-3  | Low, TD-5             |
  | ------- |--------------------- | --------------------- |
  | #FFEE58 | yellow lighten-1     | Medium, TD-4, TD-2    |
  | ------- |--------------------- | --------------------- |
  | #F44336 | red                  | High, TD-1, Emergency |
  | ------- |--------------------- | ----------------------|
*/

const td = {
  '': '#90A4AE', // blue-grey lighten-2
  None: '#66BB6A', // green lighten-1
  'TD-5': '#00B0FF', // light-blue accent-3
  'TD-4': '#FFEE58', // yellow lighten-1
  'TD-2': '#FFEE58', // yellow lighten-1
  'TD-1': '#F44336', // Red
  Emergency: '#F44336',
};

const severityColor = {
  None: '#66BB6A',
  Low: '#00B0FF',
  Medium: '#FFEE58',
  High: '#F44336',
};

const severityColorPicker = (level, tdScale) => ((!tdScale) ? severityColor[level] : td[level]);

export default severityColorPicker;
