<template>
  <v-container fluid v-if="!loadingImages">
    <v-row>
      <v-col cols="12">
        <OriginalImagesContent
          v-if="!loadingSubscription || Object.keys(currentImage).length > 0"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { find } from '@methods/helpers';
import OriginalImagesContent from '@views/image_tabs/OriginalImagesContent.vue';

export default {
  name: 'OriginalImages',
  props: ['pid'],
  components: {
    OriginalImagesContent,
  },
  data: () => ({
    initialAmount: 0,
  }),
  methods: {
    ...mapActions(['getSubscriptionById', 'getCurrentUsageRecord', 'getCustomer', 'getImagesByProject']),
  },
  watch: {
    amountImages(newAmount) {
      this.initialAmount = newAmount;
    },
  },
  computed: {
    ...mapGetters([
      'currentProject',
      'allImages',
      'currentCompany',
      'uploadingImages',
      'amountImages',
      'currentSubscription',
      'loadingSubscription',
      'currentCustomer',
      'loadingImages',
      'currentImage',
    ]),
    noImages() {
      if (this.allImages.length > 0) {
        return this.uploadingImages;
      }
      return true;
    },
  },
  // eslint-disable-next-line consistent-return
  async created() {
    this.getImagesByProject({ cid: this.currentCompany.cid, pid: this.currentProject.pid });

    try {
      let customer;
      if (Object.keys(this.currentCustomer).length === 0) {
        customer = await this.getCustomer(this.currentCompany.customerId);
      } else customer = this.currentCustomer;
      const { subscriptions } = customer;
      const processPlan = find(subscriptions.data, (subscription) => subscription.plan.product === 'prod_IUrhyebICPjpwU');

      if (processPlan) {
        const result = await this.getSubscriptionById(processPlan.id);
        if (result.type === 'success') {
          const { subscription } = result;
          const [subscriptionItem] = subscription.items.data;

          await this.getCurrentUsageRecord(subscriptionItem.id);
        }
      }
    } catch (err) {
      return err;
    }
  },
};
</script>
