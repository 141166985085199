<template>
  <div>
    <div ref="map" class="map" id="viewDiv"></div>
    <div
      id="custom-append-btn"
      class="esri-component esri-widget--button esri-widget"
      role="button"
    >
      <span
        title="Append AI Processing"
        id="append-button"
        class="esri-icon esri-icon-upload"
      ></span>
    </div>
    <div
      id="custom-reset-btn"
      class="esri-component esri-widget--button esri-widget"
      role="button"
    >
      <span
        title="Clear AI Processing"
        id="reset-button"
        class="esri-icon esri-icon-applications"
      ></span>
    </div>
  </div>

</template>

<script>
import esri from '@components/images/map/esri/load-modules';
import highestSeverity from '@components/images/map/esri/highest-severity';
import { mapGetters } from 'vuex';

export default {
  name: 'EsriMap',
  props: {
    images: {
      type: Array,
      default: Array,
    },
    structures: {
      type: Array,
      default: Array,
    },
  },
  data() {
    return {
      map: null,
      view: null,
      graphicsLayer: null,
      featureLayer: null,
      createQuery: null,
    };
  },
  computed: {
    ...mapGetters(['currentProject']),
  },
  methods: {
    async resetAttributes() {
      const cb = async (structure) => {
        const objectId = structure.path.toUpperCase().replace('POLE ', '');
        const feature = await this.getFeatureById(objectId);

        if (feature) {
          // const images = this.images.filter((img) => img.folder === structure.path);
          feature.attributes.Polymer_Insulators_Flashed = null;
          feature.attributes.Porcelain_Insulators_Broken = null;
          feature.attributes.Porcelain_Insulators_Flashed = null;
          feature.attributes.Porcelain_Insulators_Contaminated = null;
          feature.attributes.Cross_Arm_Damaged = null;
          feature.attributes.Wood_Pole_Cap_Problems = null;
          feature.attributes.Wood_Pole_Cavities = null;
          feature.attributes.Connectors_Corroded = null;
          feature.attributes.Misaligned_Hardware = null;
          feature.attributes.Cotter_Pin_Missing_Loose = null;
          feature.attributes.Joint_Rusting = null;
          feature.attributes.Ground_Bonds_Broken = null;
          feature.attributes.Dampers_Damaged = null;

          // const imagelabels = images.map((image) => {
          //   const { labels } = image.process_tracking.slice(-1)[0];
          //   return labels;
          // });
          // const allLabels = imagelabels.flat();
          // const labelCount = {};
          // allLabels.reduce((accumulator, currentValue) => {
          //   const { label } = currentValue;
          //   const attribute = label.replaceAll(' ', '_');
          //   if (!labelCount[attribute]) {
          //     labelCount[attribute] = null;
          //   } else {
          //     // eslint-disable-next-line operator-assignment
          //     labelCount[attribute] = null;
          //   }

          //   return accumulator;
          // }, labelCount);

          feature.attributes.Site_Name = '';
          feature.attributes.Image_Name = '';
          feature.attributes.Project = '';

          feature.attributes.Severity = '';
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          // for (const key in labelCount) {
          //   feature.attributes[key] = null;
          //   console.log('attributes: ', feature.attributes);
          // }

          feature.attributes.Image_Url = '';
          feature.attributes.Platform_URL = '';
        }
        return feature;
      };
      try {
        const updatePromises = this.updateAttributes(cb);

        const updates = await Promise.all(updatePromises);
        const esriUpdates = updates.filter((update) => update);
        await this.featureLayer.applyEdits({
          updateFeatures: esriUpdates,
        });
        console.log('Feature updated successfully');
        alert('ArcGIS Webmap has been updated');
      } catch (err) {
        console.error('Failed to update feature.', err);
      }
    },
    async getFeatureById(objectIdToUpdate) {
      const query = this.createQuery(objectIdToUpdate);
      const result = await this.featureLayer.queryFeatures(query);
      const { features } = result;
      if (features.length === 1) return features[0];
      return null;
    },
    updateAttributes(cb) {
      return this.structures
        .filter(({ name }) => name !== 'Miscellaneous')
        .map(cb);
    },
    async pushToESRI() {
      try {
        const cb = async (structure) => {
          const objectId = structure.path.toUpperCase().replace('POLE ', '');
          const feature = await this.getFeatureById(objectId);
          if (feature) {
            const images = this.images.filter((img) => img.folder === structure.path);
            const imagelabels = images.map((image) => {
              const { labels } = image.process_tracking.slice(-1)[0];
              return labels;
            });
            const allLabels = imagelabels.flat();
            const labelCount = {};
            allLabels.reduce((accumulator, currentValue) => {
              const { label } = currentValue;
              const attribute = label.replaceAll(' ', '_');
              if (!labelCount[attribute]) {
                labelCount[attribute] = 1;
              } else {
                // eslint-disable-next-line operator-assignment
                labelCount[attribute] = labelCount[attribute] + 1;
              }

              return accumulator;
            }, labelCount);
            const [image] = images;
            feature.attributes.Site_Name = structure.path;
            feature.attributes.Image_Name = images.map(({ filename }) => filename).join(',');
            feature.attributes.Project = this.currentProject.name;
            feature.attributes.processed_image_url = image.processedImageUrl;

            feature.attributes.Severity = highestSeverity(images);
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const key in labelCount) {
              feature.attributes[key] = labelCount[key];
            }

            feature.attributes.Image_Url = image.compressedUrl;
            feature.attributes.Platform_URL = `${window.location.origin}/#/project/${this.currentProject.pid}/images/ai/${image.filename}/processed`;
          }
          return feature;
        };

        const updatePromises = this.updateAttributes(cb);

        const updates = await Promise.all(updatePromises);
        const esriUpdates = updates.filter((update) => update);
        await this.featureLayer.applyEdits({
          updateFeatures: esriUpdates,
        });
        console.log('Feature updated successfully');
        alert('The data has been successfully appended onto the ArcGIS WebMap');
      } catch (err) {
        console.error('Failed to update feature.', err);
      }
    },
    async loadMap() {
      this.$emit('loading', true);

      try {
        const {
          MapView,
          WebMap,
          GraphicsLayer,
          createMapPoint,
          FeatureLayer,
          createQuery,
        } = await esri();
        this.createQuery = createQuery;
        const map = new WebMap({
          // basemap: 'arcgis-topographic', // Basemap layer service
          portalItem: {
            id: process.env.VUE_APP_ARCGIS_WEBMAP_PORTAL_ID,
          },
        });
        const center = process.env.VUE_APP_ARCGIS_MAP_CENTER
          ? JSON.parse(process.env.VUE_APP_ARCGIS_MAP_CENTER)
          : [-88.1488805156565, 41.7915398130641];
        this.view = new MapView({
          map,
          center,
          // Longitude, latitude,
          zoom: parseInt(process.env.VUE_APP_ARCGIS_MAP_ZOOM, 10) || 13, // Zoom level
          container: this.$refs.map, // Div element
        });

        const featureLayer = new FeatureLayer({
          url: process.env.VUE_APP_ARCGIS_FEATURE_LAYER_URL,
          enableEditing: true,
          popupEnabled: true,
        });

        map.when(async () => {
          featureLayer.load().then(() => {
            map.tables.add(featureLayer);
            this.featureLayer = featureLayer;
          });
        });

        if (this.images.length > 0) {
          const graphicsLayer = new GraphicsLayer();
          map.add(graphicsLayer);

          this.images.forEach((image) => {
            const [structure] = this.structures.filter((str) => str.path === image.folder);
            const mapPoint = createMapPoint(image, structure);
            graphicsLayer.add(mapPoint);
          });

          this.view.ui.add(['custom-append-btn'], 'bottom-left');
          const appendButton = document.getElementById('custom-append-btn');
          appendButton.addEventListener('click', this.pushToESRI);

          this.view.ui.add(['custom-reset-btn'], 'bottom-left');
          const resetButton = document.getElementById('custom-reset-btn');
          resetButton.addEventListener('click', this.resetAttributes);
          map.when(() => {
            map.reorder(graphicsLayer, 1);
          });
        }

        this.$emit('loading', false);
        return this.view;
      } catch (err) {
        this.$emit('loading', false);
        return err;
      }
    },
  },
  mounted() {
    this.loadMap();
  },
};
</script>

<style scoped>
  @import url('https://js.arcgis.com/4.27/esri/themes/light/main.css');
  .map {
    width: 100%;
    height: 50vh;
  }
</style>
