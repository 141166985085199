import moment from 'moment';

const dateFormats = {
  DATETIME_LOCAL: 'YYYY-MM-DDTHH:mm', // 2017-12-14T16:34
  DATETIME_LOCAL_SECONDS: 'YYYY-MM-DDTHH:mm:ss', // 2017-12-14T16:34:10
  DATETIME_LOCAL_MS: 'YYYY-MM-DDTHH:mm:ss', // 2017-12-14T16:34:10.234
  DAY_DATE_TIME: 'dddd, MMM Do YYYY, h:mm:ss a',
  DATE_TIME: 'YYYY-MM-DD HH:MM:SS',
  DAY_DATE: 'dddd, MMM Do YYYY',
  YEAR_MONTH_DATE: 'YYYY-MM-DD', // 2017-01-23
  MONTH_DAY_YEAR: 'MM-DD-YYYY', // 01-23-2017
  FULL_MONTH_DATE_YEAR: 'MMMM-DD-YYYY',
  MONTH_DAY_YEAR_TIME: 'MM/DD/YYYY hh:mm a',
  FULL_DATE: 'dddd, MMMM Do YYYY, h:mm:ss a',
};

export default (date, format = 'DAY_DATE_TIME') => {
  let utcDate = moment.utc(date);

  if (!utcDate.isValid() && !Number.isNaN(date)) {
    const momentTime = moment(parseInt(date, 10), 'X');
    utcDate = moment.utc(momentTime);
  }

  if (date && utcDate.isValid()) {
    return utcDate.local()
      .format(dateFormats[format]);
  }

  return date;
};
