<template>
  <Loading v-if="loadingProjects || loadingEmployees" />
  <v-container fluid v-else>
    <SnackBar :snackbar="projectNotification.success" :text="projectNotification.message" />
    <div id="projects">
      <ProjectsToolBar />
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProjectsToolBar from '@components/projects/ProjectsToolBar.vue';
import Loading from '@components/common/Loading.vue';
import SnackBar from '@components/common/SnackBar.vue';
// import { uniqueArrayBy, flattenArray } from '@methods/helpers';

export default {
  name: 'Projects',
  props: ['view'],
  components: {
    Loading,
    SnackBar,
    ProjectsToolBar,
  },
  data: () => ({
    show: false,
    message: '',
  }),
  methods: {
    ...mapActions([
      'getProjects', // Check
      'setProjectsLoading',
      'finishProjectLoad',
      'clearProjects',
    ]),
    getCreateProjectStatus(status) {
      if (status.includes('successfully')) {
        this.show = true;
        this.message = status;
        setTimeout(() => {
          this.show = false;
          this.message = '';
        }, 5000);
      }
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'loadingProjects', 'loadingEmployees', 'projectNotification', 'allTeams']),
  },
  async created() {
    this.clearProjects();
    // this.setProjectsLoading();
    let query;

    // If user is admin, you can automatically retrieve all projects by CID
    if (this.currentUser.adminStatus) query = `company_id=${this.currentUser.cid}`;
    else query = `user_id=${this.currentUser.uid}`;

    await this.getProjects(query);
  },
};
</script>
