<template>
  <v-container fluid>
    <v-combobox
      v-model="filters"
      :filter="filter"
      :items="severities"
      label="Filter Label Severities"
      hide-selected
      hide-details
      multiple
      small-chips
      @change="filterAnnotes"
    >
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :color="`${item.color}`"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.text }}
          </span>
          <v-icon
            small
            @click="parent.selectItem(item)"
          >close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ item }">
        <v-chip
          :color="`${item.color}`"
          label
          small
        >
          {{ item.text }}
        </v-chip>
      </template>
    </v-combobox>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'SeverityComboBox',
  data: () => ({
    filters: [],
    severities: [
      { value: 'None', text: 'None', color: 'green' },
      { value: 'Low', text: 'Low', color: 'blue' },
      { value: 'Medium', text: 'Medium', color: 'yellow' },
      { value: 'High', text: 'High', color: 'red' },
    ],
    defaultFilters: [
      { value: 'Low', text: 'Low', color: 'blue' },
      { value: 'Medium', text: 'Medium', color: 'yellow' },
      { value: 'High', text: 'High', color: 'red' },
    ],
  }),
  computed: {
    ...mapGetters(['currentAnnotations', 'companyHas', 'currentImage']),
  },
  methods: {
    ...mapActions(['setLabelFilter']),
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1;
    },
    filterAnnotes(severityList) {
      const classOnlyLabels = severityList.map((c) => c.value);
      this.setLabelFilter(classOnlyLabels);
    },
    setDefaults() {
      if (this.companyHas('defaultSeverity')) {
        this.filters = [...this.defaultFilters];
        this.filterAnnotes(this.defaultFilters);
      } else {
        this.filters = [];
        this.filterAnnotes([]);
      }
    },
  },
  mounted() {
    this.setDefaults();
  },
  watch: {
    // TODO: Find what this is for because it causes bugs on the anno filter
    // currentAnnotations() {
    //   this.setDefaults();
    // },
    currentImage() {
      this.setDefaults();
    },
  },
};
</script>
