// severityScale is just a simple dictionary that
// scales each severity level from 0-3 (0 being the lowest and 3
// being the highest)
const severityScale = {
  None: 0,
  Low: 1,
  Medium: 2,
  High: 3,
};

// Finds the image with the highest severity level within the image set
const highestSeverityImage = (images) => images.reduce((acc, curr) => {
  const currentSeverity = curr.process_tracking.slice(-1)[0].severity;
  let previousSeverity;

  // If the accumulator is not defined yet, then just set the previous severity to
  // 'None'
  if (acc) {
    previousSeverity = acc.process_tracking.slice(-1)[0].severity;
  } else previousSeverity = 'None';

  // Compare the severityScale value of the current and previous
  // severity levels and return the image with the higher one
  if (severityScale[currentSeverity] >= severityScale[previousSeverity]) return curr;
  return acc;
});

// Calls `highestSeverityImage` to retrieve the image, then
// extracts the severity level
export default (images) => {
  const image = highestSeverityImage(images);
  const { severity } = image.process_tracking.slice(-1)[0];
  return severity;
};
