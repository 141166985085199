/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import platformBackendClient from '@backend-clients/platform-backend';

const state = {
  labels: [],
  loading: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  projectLabels: (state) => state.labels,
  loadingLabels: (state) => state.loading,
  flatProjectLabels: (state, rootGetters) => {
    const abbreviations = [];
    if (rootGetters.companyHas('categorize_labels')) {
      state.labels.forEach((orb) => {
        abbreviations.push(...orb.labels);
      });
    } else {
      abbreviations.push(...state.labels);
    }
    return abbreviations;
  },
};

const actions = {
  loadLabels({ commit }, loading) {
    commit('set_loading', loading);
  },
  async fetchLabelsForProject({ commit }, payload) {
    commit('set_loading', true);
    const {
      pid, cid, categorize, custom,
    } = payload;
    const res = await platformBackendClient.get(`/api/label/company/${cid}/project/${pid}/${categorize}/${custom}`);
    if (res.status === 200) {
      commit('create_labels', res.data);
      commit('set_loading', false);
    }
    return res;
  },

  setLabelsLoading({ commit }, loading) {
    commit('set_loading', loading);
  },
};

const mutations = {
  create_labels: (state, labels) => {
    state.labels = labels;
  },
  set_loading: (state, status) => {
    state.loading = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
