import { loadModules } from 'esri-loader';

const getSeverityColor = (severity) => {
  switch (severity) {
    case 'None':
      return [102, 187, 106];
    case 'Low':
      return [0, 176, 255];
    case 'Medium':
      return [255, 238, 88];
    case 'High':
      return [244, 67, 54];
    default:
      return [102, 187, 106];
  }
};

export default () => new Promise((resolve, reject) => {
  loadModules([
    'esri/config',
    'esri/Map',
    'esri/views/MapView',
    'esri/Graphic',
    'esri/layers/GraphicsLayer',
    'esri/symbols/SimpleMarkerSymbol',
    'esri/WebMap',
    'esri/layers/FeatureLayer',
    'esri/rest/support/Query',
    'esri/geometry/Point',
    'esri/renderers/UniqueValueRenderer',
  ])
    .then(
      ([
        esriConfig,
        Map,
        MapView,
        Graphic,
        GraphicsLayer,
        SimpleMarkerSymbol,
        WebMap,
        FeatureLayer,
        Query,
        Point,
        UniqueValueRenderer,
      ]) => {
        // eslint-disable-next-line no-param-reassign
        esriConfig.apiKey = process.env.VUE_APP_ESRI_API_KEY;

        resolve({
          Map,
          MapView,
          Graphic,
          GraphicsLayer,
          createMapPoint: (image, structure) => {
            const point = {
              type: 'point',
              longitude: image.location[1],
              latitude: image.location[0],
            };
              // eslint-disable-next-line no-unused-vars
            const { severity, labels } = image.process_tracking.slice(-1)[0];

            const marker = new SimpleMarkerSymbol({
              color: getSeverityColor(severity),
              outline: {
                color: [225, 225, 225],
                width: 1,
              },
            });

            // If structure is 'undefined', set priority as empty string
            // if structure has a value, then set priority as 'structure.overall_severity'
            // otherwise if no priority has been set, set it as empty string
            let priority;

            if (structure) {
              priority = structure.overall_severity ? structure.overall_severity : '';
            } else {
              priority = '';
            }

            const attributes = {
              severity,
              priority,
            };

            labels.forEach((l) => {
              attributes[l.label] = true;
            });

            const fieldInfos = Object.keys(attributes).map((attribute) => ({
              fieldName: attribute,
            }));

            return new Graphic({
              geometry: point,
              symbol: marker,
              attributes,
              popupTemplate: {
                title: image.filename,
                content: [
                  {
                    type: 'fields', // Autocasts as new FieldsContent()
                    // Autocasts as new FieldInfo[]
                    fieldInfos,
                  },
                ],
              },
            });
          },
          WebMap,
          FeatureLayer,
          Query,
          createQuery: (id) => {
            const query = new Query();
            query.where = `OBJECTID = ${id}`;
            query.outFields = ['*']; // Retrieve all attributes

            return query;
          },
          Point,
          UniqueValueRenderer,
          SimpleMarkerSymbol,
        });
      },
    )
    .catch((err) => reject(err));
});
