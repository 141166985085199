<template>
  <v-container fluid>
    <div id="insights-content">
      <v-row>
        <v-col cols="12">
          <DashboardToolbar
            :getMapRef="this.getMapRef" :pid="pid" :images="pImages.all" :index="index" />
        </v-col>
      </v-row>
      <v-row v-if="initialImageCount < 1">
        <v-col cols="12">
          <InsightsNoImages />
        </v-col>
      </v-row>
      <v-row v-else-if="initialProcessedImageCount < 1">
        <v-col cols="12">
          <InsightsNoProcessedImages />
        </v-col>
      </v-row>
      <v-row v-else-if="!hasMatch">
        <v-col cols="12">
          <NoImagesMatchFilter />
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col xs="12" sm="12" :md="(isMobile) ? '12' : '7'" lg="8" xl="8">
          <DashboardImage :images="pImages.all" />
          <div id="map-loader" class="pa-2" v-if="loadingImages">
            <SkeletonLoader type="image"/>
            <SkeletonLoader type="image" />
          </div>
          <div id="maps" v-else>
            <EpriMap
              v-if="companyHas('newfoundland_map')"
              :images="pImages.withLocation"
              :folders="folders.withLocation"
              :key="folders.withLocation.length"
            />
            <GeneralMap
              v-else
              ref="mapRef"
              :images="pImages.withLocation"
              :key="pImages.withLocation.length"
            />
          </div>
        </v-col>
        <v-col
          v-if="!isMobile"
          xs="12"
          sm="12"
          :md="(isMobile) ? '12' : '5'"
          lg="4" xl="4"
        >
          <Collapsibles :images="pImages.all" :pid="pid" />
          <v-card elevation="4">
            <v-card-title class="headline">
              Filter Structure and Image Data
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <DashboardFilters />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-sheet class="mx-auto" elevation="8" v-if="loadingImages">
            <SkeletonLoader type="card" />
          </v-sheet>
          <FaultSummaryCards :images="pImages.all" v-else/>
        </v-col>
        <v-col cols="12">
          <DashboardCharts :images="pImages.all"/>
        </v-col>
        <v-col cols="12">
          <FaultDetections :images="pImages.all" />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardToolbar from '@components/dashboard/navigations/DashboardToolbar.vue';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';
import NoImagesMatchFilter from '@components/dashboard/NoImagesMatchFilter.vue';
import DashboardImage from '@components/dashboard/DashboardImage.vue';
import Collapsibles from '@components/dashboard/Collapsibles.vue';
import FaultSummaryCards from '@components/dashboard/fault-summary/FaultSummaryCards.vue';
import GeneralMap from '@components/images/map/GeneralMap.vue';
import EpriMap from '@components/images/map/EpriMap.vue';
import DashboardCharts from '@components/dashboard/DashboardCharts.vue';
import DashboardFilters from '@components/dashboard/filters/DashboardFilters.vue';
import FaultDetections from '@components/dashboard/fault-detections/FaultDetections.vue';
import InsightsNoImages from '@components/dashboard/InsightsNoImages.vue';
import InsightsNoProcessedImages from '@components/dashboard/InsightsNoProcessedImages.vue';
import dashboardHelpers from '@components/dashboard/methods';
import { getFolderIdsByPathArray } from '@utils/folder';

export default {
  name: 'InsightsContent',
  props: ['pid'],
  components: {
    DashboardToolbar,
    DashboardImage,
    FaultSummaryCards,
    GeneralMap,
    Collapsibles,
    EpriMap,
    DashboardFilters,
    InsightsNoImages,
    DashboardCharts,
    FaultDetections,
    InsightsNoProcessedImages,
    SkeletonLoader,
    NoImagesMatchFilter,
  },
  data: () => ({
    index: 0,
    annotateDialog: false,
    initialProcessedImageCount: 0,
    initialImageCount: 0,
    hasMatch: true,
    attrs: {
      class: 'mb-6',
      boilerplate: false,
      elevation: 2,
    },
  }),
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    ...mapActions(['setCurrentImage', 'getDashboardImagesByFilters', 'clearDashboardFilters', 'getDashboardData', 'setImagesLoading', 'setLazyLoadPointer']),
    // Checks if the image list is empty in order to display the
    // no original images message
    hasOriginalImages: (images) => images.length > 0,

    // Checks if the image list has any processed images
    // No processed images => no processed images view
    hasProcessedImages: (images) => images.filter((image) => image.processedImageUrl).length > 0,

    // Closes the annotation dialog
    closeAnnotation() {
      this.annotateDialog = false;
      if (this.fromEnlargeDialog) {
        this.$refs.imgViewBtn.$el.click();
      }
    },
    // getMapRef
    getMapRef() {
      return this.$refs.mapRef;
    },
    // Formats the processed image entities
    getProcessedImages(images) {
      return dashboardHelpers.formatProcessedImages(images);
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'allFolders', 'currentCompany', 'currentProject', 'allImages', 'small', 'extraSmall', 'isLaptop', 'companyHas', 'allFolders', 'dashboardFilters', 'loadingImages', 'lazyLoadPointer']),
    // Tracks the viewports of the page to decide whether or not it should
    // rearrange the layouts of the dashboard to make it mobile friendly
    isMobile() {
      return (this.small || this.extraSmall) || !this.isLaptop;
    },

    // Computes all images, images with no location data, and images with location data
    pImages() {
      const allProcessedImages = this.getProcessedImages(this.allImages);

      if (window.location.href === 'nfpower.buzzpowerai.com') {
        this.allImages.filter((image) => {
          const { originalImageUrl, processedImageUrl } = image;

          return originalImageUrl.includes('buzz-powerai-cbucket-epri-newfoundland-test')
            || processedImageUrl.includes('buzz-powerai-cbucket-epri-newfoundland-test');
        });
      }

      return {
        all: allProcessedImages,
        noLocation: dashboardHelpers.getNoLocationImages(allProcessedImages),
        withLocation: dashboardHelpers.getLocatedImages(allProcessedImages),
      };
    },
    folders() {
      const noLocation = this.allFolders.filter((folder) => {
        if (!folder.location) return true;

        return !Object.keys(folder).includes('location')
          || folder.location.length < 2
          || (folder.location[0] === null && folder.location[1] === null);
      });

      const withLocation = this.allFolders.filter((folder) => {
        if (!folder.location) return false;
        return (Object.keys(folder).includes('location')
          || folder.location.length === 2)
          && (folder.location[0] !== null && folder.location[1] !== null);
      });

      const filterFolderByPriority = (folders) => folders
        .filter((folder) => {
          if (this.dashboardFilters.priorities.length < 1) return true;
          return this.dashboardFilters.priorities.includes(folder.overall_severity);
        });
      return {
        all: filterFolderByPriority(this.allFolders),
        withLocation: filterFolderByPriority(withLocation),
        noLocation: filterFolderByPriority(noLocation),
      };
    },
  },
  watch: {
    lazyLoadPointer(newPointer) {
      const currentImage = this.pImages.all[newPointer];
      if (currentImage.id !== this.currentImage.id) {
        this.setCurrentImage(currentImage);
      }
    },
    dashboardFilters: {
      handler(newVal) {
        const query = {
          ...newVal,
          structures: getFolderIdsByPathArray(newVal.folders),
        };
        delete query.folders;
        const payload = {
          cid: this.currentCompany.cid,
          pid: this.currentProject.pid,
          query,
        };

        this.getDashboardImagesByFilters(payload)
          .then((res) => {
            const images = res.data;
            this.hasMatch = images.length > 0;
          });
      },
      deep: true,
    },
  },
  created() {
    this.initialImageCount = this.allImages.length;
    this.initialProcessedImageCount = this.pImages.all.length;
  },
  destroyed() {
    this.clearDashboardFilters();
    this.setLazyLoadPointer(0);
  },
};
</script>
