<template>
  <div class="image-upload">
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider></v-tabs-slider>
      <v-tab id="file-upload-tab">File Uploader</v-tab>
      <v-tab id="folder-upload-tab">Folder Uploader</v-tab>
      <v-tab id="Skydio-import-tab">Skydio Import</v-tab>
      <v-tab id="csv-import-tab">CSV Import</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <form>
          <vue-dropzone
            ref="directDropzone"
            id="drop"
            :options="dropzoneOptions"
            @vdropzone-upload-progress="onUploadProgress"
            @vdropzone-total-upload-progress="totalUploadProgress"
            @vdropzone-sending="beforeSend"
            @vdropzone-file-added="addedFile"
            @vdropzone-files-added="handleAddedFiles"
            @vdropzone-queue-complete="onQueueComplete"
            @vdropzone-complete="uploadCompleted"
            style="max-height: 500px; overflow: scroll;"
          ></vue-dropzone>
          <v-progress-linear
            indeterminate
            color="primary"
            v-if="uploadingImages"
          ></v-progress-linear>
          <p class="text-center">
            {{ (uploadingImages) ?
              'Give us a moment to finish up the upload'
              : `${totalProgress.toFixed(2)} %`}}
          </p>
        </form>
      </v-tab-item>
      <v-tab-item>
        <div style="text-align:center;">
          <v-btn color="primary" class="ma-2 white--text" @click="selectFolder">
            Select a Folder
            <v-icon right dark>mdi-folder</v-icon>
          </v-btn>
          <input
            style="display:none;"
            type="file"
            name="fileList"
            id="folderUploadInput"
            @change="onFolderSelected"
            webkitdirectory multiple />
        </div>
      </v-tab-item>
      <v-tab-item>
        <div>
          <div>
            <v-select suffix="Days"
                      v-model="withIn"
                      label="Takeoff With-in"
                      :items="[7,30,90,180]" />
          </div>
          <v-data-table
            :headers="headers"
            :loading="flightLoading"
            :loading-text="'Loading Skydio Flights...'"
            :items="skydioFlights"
          >
            <template v-slot:item.takeoff="{ item }">
              <span>{{ dateFormat(item.takeoff, 'MONTH_DAY_YEAR_TIME') }}</span>
            </template>
            <template v-slot:item.landing="{ item }">
              <span>{{ dateFormat(item.landing, 'MONTH_DAY_YEAR_TIME') }}</span>
            </template>
            <template v-slot:item.user="{ item }">
            <span>{{

                `${item.user.first_name} ${item.user.last_name}`  }}
            </span>
            </template>
            <template v-slot:item.vehicle_serial="{ item }">
            <span>{{
                item.vehicle_serial }}
            </span>
            </template>
            <template v-slot:item.import="{ item }">

              <v-btn icon @click="handleSkydioImport(item.flight_id)">
                <v-icon>mdi-import</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>

      </v-tab-item>
      <v-tab-item>
        <div style="text-align:center;">
          <v-btn color="primary" class="ma-2 white--text" @click="selectCsv">
            Select a CSV File
            <v-icon right dark>mdi-file</v-icon>
          </v-btn>
          <input
            style="display:none;"
            type="file"
            name="csvFile"
            id="csvImportInput"
            accept=".csv"
            @change="onCsvSelected"/>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <v-overlay :value="showOverlay" style="text-align:center;" >
      <v-progress-circular
      :rotate="-90"
      :size="100"
      :width="15"
      :indeterminate="indeterminate"
      :value="processedPercent"
      color="white"
    >
      {{ processedPercent }}
    </v-progress-circular>
    <div>{{uploadStatus}}</div>
    </v-overlay>
    <DialogMessage
      :show="this.showMessage"
      @updateShow="this.updateShow"
      :title="'Max Size Exceed'"
      :description="'The total file size of the folder ' +
       'exceed the size limit of 2GB. ' +
       'For large file upload, please contact us for assistance '" />
    <v-snackbar
      v-model="snackbar"
      :timeout="-1"
    >
      <div  style="display: flex;justify-content: space-between;align-items: center; ">
        <v-progress-circular
          style="margin: 0px 10px"
          indeterminate
          color="primary"
          size="50"
        >
        </v-progress-circular>
        <div v-if="uploadingImages" style="font-size: 20px">
          File Uploaded {{totalUploadPercent === 0 ? '': totalUploadPercent }}
        </div>
        <div v-else style="font-size: 20px">
          Processing File {{
            totalFileCount !== 0 ?
              `${processedFileCount} / ${totalFileCount}`
              :
              ''
          }}
        </div>
        <a v-if="!uploadingImages" @click="clearUploadStatus"> X </a>
      </div>

    </v-snackbar>
    <v-overlay :value="showImportOverlay" style="text-align:center;" >
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        indeterminate
        color="white"
      >
      </v-progress-circular>
      <div>{{`importing file ${importStatus}`}}</div>
    </v-overlay>
  </div>
</template>
<script>
import DialogMessage from '@components/common/DialogMessage.vue';
import vue2Dropzone from 'vue2-dropzone';
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import services from '@services';
import moment from 'moment';
import hash from '@utils/hash';
import parallelLimit from 'run-parallel-limit';
import { clearFirebaseRecords, firebaseListener, getUploadStatus } from '@utils/upload';
import dateFormat from '@utils/time-formats';
import firebase from '../../../firebase';

export default {
  name: 'DirectImageUploader',
  components: {
    DialogMessage,
    vueDropzone: vue2Dropzone,
  },
  props: ['cid', 'pid'],
  data() {
    return {
      snackbar: false,
      flightLoading: false,
      withIn: 30,
      headers: [
        { text: 'Flight ID', value: 'flight_id' },
        { text: 'Takeoff', value: 'takeoff' },
        { text: 'Landing', value: 'landing' },
        { text: 'Pilot', value: 'user' },
        { text: 'Vehicle Serial', value: 'vehicle_serial' },
        { text: 'Image Count', value: 'count' },
        { text: 'Import', value: 'import' },
      ],
      totalFileCount: 0,
      uploadedFileCount: 0,
      processedFileCount: 0,
      showImportOverlay: false,
      skydioFlights: [],
      tab: null,
      showMessage: false,
      showOverlay: false,
      indeterminate: false,
      uploadStatus: '',
      processedPercent: 0,
      importStatus: '',
      cloudsvg: '../../../../../static/img/upload.svg',
      fillColor: 'LightSkyBlue',
      totalProgress: 0,
      fileListLength: 0,
      totalFiles: 0,
      uploadedFiles: 0,
      dropzoneOptions: {
        paramName: 'file',
        acceptedFiles: 'image/*',
        url: 'https://storage.googleapis.com/',
        thumbnailWidth: 200,
        addRemoveLinks: true,
        dictDefaultMessage: "<div class='v-list-item__icon'><i aria-hidden='true' class='v-icon notranslate material-icons theme--light light-blue--text text--lighten-3' style='font-size:200px;'>cloud_upload</i></div>",
        createImageThumbnails: false,
        autoProcessQueue: false,
        parallelUploads: 5,
        timeout: 3600 * 1000,
        maxFilesize: null,
        renameFile: async (file) => {
          const newFile = await hash(file);
          return newFile.renamedFilename;
        },
      },
      filePolicies: {},
      refreshingPolicies: false,
      refreshPoliciesInterval: null,
    };
  },
  methods: {
    dateFormat,
    ...mapActions(['setFolders', 'setUploadPercent', 'setNewImageAmount', 'setNotification', 'setUploading', 'getProject', 'createFolder', 'createImages']),
    clearUploadStatus() {
      // eslint-disable-next-line no-restricted-globals,no-empty
      if (confirm('By aborting the image process, any unprocessed images will not be saved. \nAre you sure you want to abort ')) {
        this.snackbar = false;
        this.totalFileCount = 0;
        this.processedFileCount = 0;
        this.uploadedFileCount = 0;
        clearFirebaseRecords(this.pid);
      }
    },
    async handleSkydioImport(flightId) {
      this.showImportOverlay = true;
      const projectId = this.currentProject.pid;
      const companyId = this.currentCompany.cid;
      // default all images to misc folder
      const { id: structureId } = this.allFolders.find((folder) => folder.path === 'Miscellaneous');
      const { data } = await services.skydio.importByFlight(
        { companyId, projectId, structureId }, flightId,
      );
      const unsubscribe = firebase.database.collection('imports/').doc(data.importId).onSnapshot(async (snapshot) => {
        const importData = snapshot.data();
        const {
          done, imported, total, status,
        } = importData;
        if (total > 0) this.importStatus = `${imported} / ${total}`;
        if (done || status === 'failed') {
          unsubscribe();
          if (status === 'failed') {
            alert('Import Failed');
            this.showImportOverlay = false;
          } else {
            const { data: images } = await services.images.getByProject(this.cid, this.pid);
            const { data: folders } = await services.folders.getByProject(this.cid, this.pid);
            this.setFolders(folders);
            this.$store.commit('create_images', images);
            this.$store.commit('set_amount');
            this.showImportOverlay = false;
          }
        }
      });
    },
    updateShow(value) {
      this.showMessage = value;
    },
    uploadCompleted() {
      this.uploadedFiles += 1;
    },
    /* eslint no-param-reassign: "error" */
    addedFile(file) {
      file.previewElement.querySelector('[data-dz-name]').textContent = file.name;
    },

    totalUploadProgress() {
      this.setUploadPercent(`${this.uploadedFiles} / ${this.totalFiles}`);
    },
    // eslint-disable-next-line no-unused-vars
    onUploadProgress(file, progress, bytesSent) {
      this.uploadProgress = Number.parseFloat(progress).toFixed(2);
    },
    // eslint-disable-next-line no-unused-vars
    async beforeSend(file, xhr, formData) {
      await this.preUploadProcess(file, formData);
    },
    async preUploadProcess(file, formData) {
      // eslint-disable-next-line no-empty
      while (this.refreshingPolicies) {
        const timeout = () => new Promise((resolve) => setTimeout(() => resolve(), 500));
        // eslint-disable-next-line no-await-in-loop
        await timeout();
      }
      const params = this.filePolicies[file.renamedFilename].fields;
      formData.append('Content-Type', file.type);
      Object.keys(params).forEach((p) => {
        formData.append(p, params[p]);
      });
      delete this.filePolicies[file.renamedFilename];
      return formData;
    },
    async handleAddedFiles() {
      this.$refs.directDropzone.setOption('url', this.uploadUrl);
      this.$refs.directDropzone.setOption('clickable', false);
      this.setUploading(true);
      await new Promise((r) => setTimeout(r, 1000));
      const dfiles = this.$refs.directDropzone.getAcceptedFiles();
      this.totalFiles = dfiles.length;
      this.uploadedFiles = 0;
      await this.handleUploadFiles(dfiles);
      this.$refs.directDropzone.processQueue();
      const elementsToModify = ['dz-image', 'dz-details', 'dz-progress', 'dz-success-mark'];
      elementsToModify.forEach((className) => this.noZIndex(className));
    },
    async onQueueComplete() {
      const uploadedFiles = this.$refs.directDropzone.getAcceptedFiles().filter((file) => file.status === 'success');
      await this.postUploadProcess(uploadedFiles);
      this.$refs.directDropzone.setOption('clickable', false);
    },
    noZIndex(className) {
      const elements = document.getElementsByClassName(className);
      Object.keys(elements).forEach((element) => elements[element].setAttribute('style', 'z-index: 0'));
    },
    // Get signed urls
    async handleUploadFiles(uploadFiles, folderName = 'Miscellaneous') {
      // firebase only allows 500 documents per batch write .
      const batchSize = Math.round(uploadFiles.length / 500);
      const batches = [firebase.database.batch()];
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < batchSize; i++) {
        batches.push(firebase.database.batch());
      }
      try {
        const sid = this.allFolders.find((f) => f.path === folderName)?.id;
        const files = await Promise.all(uploadFiles.map(async (file, index) => {
          const batchIndex = Math.floor(index / 500);
          const batch = batches[batchIndex];
          const f = (file.renamedFilename)
            ? file
            : await hash(file);
          const filename = f.renamedFilename;
          const fbPayload = {
            companyId: this.currentCompany.cid,
            projectId: this.currentProject.pid,
            structureName: folderName,
            created: false,
            error: null,
            filename: file.name,
            bucket: this.currentCompany.bucket,
            retry: 0,
            uid: this.currentUser.firestore_uid,
          };
          if (sid) {
            fbPayload.structureId = sid;
          }
          // create firebase record
          batch.set(firebase.database.collection('upload').doc(f.id), fbPayload);
          return { contentType: file.type, filename: `uploaded/${filename}`, renamedFilename: filename };
        }));
        await Promise.all(batches.map((b) => b.commit()));
        // await batch.commit();
        const payload = {
          cid: this.currentCompany.firestore_cid,
          pid: this.currentProject.firestore_pid,
          data: files,
        };
        const res = await services.storage.signed_url(this.currentCompany.bucket, payload);
        files.forEach((f) => {
          if (res.data[f.renamedFilename]) {
            res.data[f.renamedFilename].fileObj = f;
          }
        });
        this.filePolicies = { ...this.filePolicies, ...res.data };
      } catch (err) {
        console.log(err);
        this.setNotification({
          message: 'There was an issue uploading the images',
          success: true,
          color: 'error',
        });
      }
    },
    async refreshPolicies() {
      if (Object.keys(this.filePolicies).length > 0) {
        this.refreshingPolicies = true;
        // eslint-disable-next-line max-len
        const files = Object.keys(this.filePolicies).map((filename) => this.filePolicies[filename].fileObj);
        const payload = {
          cid: this.currentCompany.firestore_cid,
          pid: this.currentProject.firestore_pid,
          // eslint-disable-next-line max-len
          data: files,
        };
        const { data } = await services.storage.signed_url(this.currentCompany.bucket, payload);
        files.forEach((f) => {
          if (data[f.renamedFilename]) {
            data[f.renamedFilename].fileObj = f;
          }
        });
        this.filePolicies = { ...data };
        this.refreshingPolicies = false;
      }
    },
    async postUploadProcess(uploadedFiles) {
      if (uploadedFiles.length === 0) return;
      this.setUploading(false);
      this.showOverlay = false;
      this.$refs.directDropzone.removeAllFiles();
    },
    async onFolderSelected(event) {
      this.setUploading(true);
      const imageFiles = Array.from(event.target.files)
        .filter((file) => file.type.indexOf('image/') === 0);
      this.totalFiles = imageFiles.length;
      this.uploadedFiles = 0;
      this.totalUploadProgress();
      document.getElementById('folderUploadInput').value = null;
      this.uploadStatus = 'Hashing image files, please wait ....';
      try {
        const files = imageFiles
          .map(((file) => hash(file)));
        this.indeterminate = false;
        this.uploadStatus = 'Uploading image files, please wait ....';
        const folderName = files[0].webkitRelativePath.split('/')[0];

        // if folder does not exist, call create folder
        if (!this.allFolders.find((folder) => folder.path === folderName)) {
          const payload = {
            company_id: this.cid,
            project_id: this.pid,
            data: {
              pid: this.currentProject.firestore_pid,
              cid: this.currentCompany.firestore_cid,
              structure: {
                name: folderName,
                structuretype_id: 0,
                lat: null,
                long: null,
                severity: null,
                project_id: this.currentProject.pid,
                line_id: null,
                work_order: false,
                reviewed: false,
                second_reviewer: null,
                note: '',
                image_count: event.target.files.length,
                complete_inspection: false,
                cid: this.currentCompany.firestore_cid,
                date: moment(),
                processed_image_count: 0,
                clustering_uncertainty: false,
                field_inspection: false,
              },
            },
          };

          await this.createFolder(payload);
        }
        await this.handleUploadFiles(files, folderName);
        // upload files
        //  this.showOverlay = true;
        const uploadProcess = files.map((file) => async (callback) => {
          const data = new FormData();
          await this.preUploadProcess(file, data);
          data.append('file', file);
          const axiosForUpload = axios.create({});

          delete axiosForUpload.defaults.headers.common.Authorization;
          delete axiosForUpload.defaults.headers.common.database;

          axiosForUpload.post(this.uploadUrl, data).then(() => {
            this.uploadedFiles += 1;
            this.setUploadPercent(`${this.uploadedFiles} / ${this.totalFiles}`);
            callback(null);
          }).catch(() => {
            callback('Failed to upload file');
          });
        });
        parallelLimit(uploadProcess, 5, async () => {
          this.showOverlay = false;
          await this.postUploadProcess(files, folderName);
        });
      } catch (e) {
        console.error(e);
        this.setNotification({
          success: true,
          message: 'Failed to upload images within the folder',
          color: 'error',
        });
      }
    },
    selectFolder() {
      document.getElementById('folderUploadInput').click();
    },
    selectCsv() {
      document.getElementById('csvImportInput').click();
    },
    async onCsvSelected(event) {
      this.showImportOverlay = true;
      const self = this;
      const csvFile = event.target.files[0];
      document.getElementById('csvImportInput').value = null;
      const reader = new FileReader();
      reader.onload = async function (evt) {
        const csvData = evt.target.result;
        csvData.replaceAll('/n', '');
        const arrayData = csvData.split('\r').slice(0, 17);
        const csvDataArray = arrayData.map((row) => row.split(','));
        csvDataArray.shift();
        const payload = {
          fieldNames: ['name', 'long', 'lat'],
          data: csvDataArray,
        };
        const { data } = await services.images.csvImport(self.cid, self.pid, payload);
        const { data: folders } = await services.folders.getByProject(self.cid, self.pid);
        self.setFolders(folders);
        self.showImportOverlay = false;
        self.$store.commit('create_images', data);
        self.$store.commit('set_amount');
      };
      reader.readAsText(csvFile);
    },
    async getFlights() {
      this.flightLoading = true;
      const { data: flights } = await services.skydio.getFlights(this.withIn);
      this.skydioFlights = flights;
      this.flightLoading = false;
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'totalUploadPercent', 'currentProject', 'allImages', 'uploadingImages', 'currentCompany', 'companyConfig', 'allFolders']),
    folderPath() {
      return `original/${this.currentProject.pid}`;
    },
    uploadUrl() {
      return `https://storage.googleapis.com/${this.currentCompany.bucket}/`;
    },
    defaultStructure() {
      const { customFolder } = this.currentCompany.config;
      const [structure] = this.allFolders.filter((folder) => folder.path === customFolder);

      return structure.id;
    },
  },
  watch: {
    withIn() {
      this.getFlights();
    },
    uploadingImages(uploading) {
      if (uploading) {
        this.totalFileCount = 0;
        this.processedFileCount = 0;
        this.uploadedFileCount = 0;
      }
      if (!uploading && this.refreshPoliciesInterval) {
        clearInterval(this.refreshPoliciesInterval);
      }
      // eslint-disable-next-line max-len
      // setup interval to refresh policies every 10 minutes since policies will be expired after 15 minutes
      if (uploading && !this.refreshPoliciesInterval) {
        this.refreshPoliciesInterval = setInterval(async () => {
          await this.refreshPolicies();
        }, 600000);
      }
    },
  },
  async created() {
    // get skydio flights
    this.getFlights();
    const { pid } = this;
    if (Object.keys(this.currentProject).length === 0) {
      this.getProject({ pid, cid: this.currentCompany.cid });
    }
    this.totalFileCount = 0;
    this.processedFileCount = 0;
    this.uploadedFileCount = 0;
    await getUploadStatus(pid);
    firebaseListener(pid, (type, doc, update) => {
      switch (type) {
        case 'added':
          this.totalFileCount += 1;
          if (!this.snackbar) {
            this.snackbar = true;
          }
          break;
        case 'removed':
          this.processedFileCount += 1;
          if (this.processedFileCount === this.totalFileCount) {
            this.snackbar = false;
          }
          if (this.processedFileCount === this.totalFileCount) {
            update();
          }
          break;
        default: break;
      }
    });
  },
};
</script>

<style scoped>
.display-none{
  display: none;
}
</style>
