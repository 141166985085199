import platformBackendClient from '@backend-clients/platform-backend';

export default {
  get: (cid, pid) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/dashboard/company/${cid}/project/${pid}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  filter: (cid, pid, query) => new Promise((resolve, reject) => {
    platformBackendClient.get(`/api/dashboard/company/${cid}/project/${pid}/filter`, { params: query })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  }),
  getJsonData: (cid, pid) => platformBackendClient.get(`/api/dashboard/company/${cid}/project/${pid}/json`),
};
