<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="employees"
      class="elevation-1"
      :loading="loadingUsers"
      id="user-table"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title id="user-table-title">{{ title }}</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="mb-2"
                v-bind="attrs"
                id="add-user-btn"
                icon
                v-on="on"
              ><v-icon>group_add</v-icon></v-btn>
            </template>
            <!-- Add user form -->
            <v-card>
              <v-card-title>
                <span class="headline" id="add-user-form-title">{{ formTitle }}</span>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  icon
                  @click="close"
                  id="close-new-user-btn"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text>
                <v-form ref="createForm" id="new-user-form">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.firstName"
                        label="First Name"
                        outlined
                        id="new-user-first-name"
                        :error-messages="userError.firstName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field
                        v-model="editedItem.lastName"
                        label="Last Name"
                        id="new-user-last-name"
                        outlined
                        :error-messages="userError.lastName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email Address"
                        outlined
                        id="new-user-email"
                        :error-messages="userError.email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-select
                        outlined
                        label="Country"
                        :items="countries"
                        :disabled="editedItem.phone.length === 0"
                        item-text="name"
                        item-value="Iso2"
                        id="new-user-phone-country-code"
                        v-model="editedItem.country"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field
                        v-model="editedItem.phone"
                        @input="formatPhone"
                        label="Phone Number"
                        id="new-user-phone"
                        outlined
                        :error-messages="userError.phone"
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="6">
                      <v-select
                        label="Role"
                        :items="roles"
                        item-text="name"
                        item-value="adminStatus"
                        v-model="editedItem.adminStatus"
                        outlined
                        disabled
                      ></v-select>
                    </v-col> -->
                  </v-row>
                </v-container>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
                  id="close-new-user-btn"
                >Cancel</v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
                  id="create-new-user-btn"
                >Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.adminStatus`]="{ item }">
        <v-chip
         :color="chipColorPicker(item.adminStatus)"
         x-small
         dark
        >{{ item.adminStatus}}</v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div
          :style="
            {display: 'flex',justifyContent: 'space-between',width:'50px'}
           "
          :id="`user-${item.uid}-delete-btn`"
        >
          <v-icon
            small
            @click="confirmAction(item,'delete')"
            :id="`delete-user-${item.uid}-btn`"
          >
            mdi-delete
          </v-icon>
          <v-icon
            v-if="shouldRenderStatusActionBtn(item)"
            small
            @click="confirmAction(item,item.status === 'Active'? 'disable':'enable')"
            :color="item.status === 'Active'? 'red':'green'"
            :id="`disable-user-${item.uid}-btn`"
          >
            {{item.status === 'Active'? 'mdi-account-cancel': 'mdi-account-check' }}
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="actionDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline" id="action-dialog-title">{{ dialogTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text id="action-dialog-content">
          Are you sure you want to {{dialogType}} this user?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="actionDialog = false" id="cancel-action-btn">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="handleDialog" id="confirm-action-btn">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import services from '@services';
import countries from '@utils/country-codes';

export default {
  name: 'EmployeesTable',
  props: ['title', 'employees', 'cid'],
  data: () => ({
    dialog: false,
    dialogType: 'delete',
    actionDialog: false,
    roles: [
      { name: 'Admin', adminStatus: true },
      { name: 'User', adminStatus: false },
    ],
    countries: [],
    dialogTitle: 'User Action',
    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: true,
        value: 'firstName',
      },
      {
        text: 'Last Name',
        sortable: true,
        value: 'lastName',
      },
      { text: 'Phone Number', value: 'phone' },
      { text: 'Email', value: 'email' },
      { text: 'Admin', value: 'adminStatus' },
      { text: 'Status', value: 'status' },
      {
        text: 'Actions', value: 'actions', sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      country: '',
      adminStatus: false,
    },
    defaultItem: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      country: '',
      adminStatus: false,
    },
    itemToDelete: null,
    deleteDialog: false,
  }),
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close();
    },
  },
  methods: {
    ...mapActions(['createUser', 'deleteUser', 'setNotification', 'setUserError', 'updateUserStatus']),
    formatPhone() {
      const number = this.editedItem.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.editedItem.phone = !number[2] ? number[1] : `(${number[1]}) ${number[2]}${number[3] ? `-${number[3]}` : ''}`;
    },
    chipColorPicker(item) {
      return (item === 'Admin') ? 'primary' : 'blue-grey darkened-4';
    },
    editItem(item) {
      this.editedIndex = this.allEmployees.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    confirmAction(item, type) {
      this.itemSelected = item;
      this.dialogTitle = `${type.charAt(0).toUpperCase() + type.slice(1)} User`;
      this.dialogType = type;
      this.actionDialog = true;
    },
    setSnack(color = '', message = '') {
      this.setNotification({
        color,
        message,
        success: true,
      });
    },
    // eslint-disable-next-line consistent-return
    handleDialog() {
      if (this.dialogType === 'delete') {
        return this.deleteItem();
      }
      if (this.dialogType === 'disable') {
        return this.enableUser(false);
      }
      if (this.dialogType === 'enable') {
        return this.enableUser(true);
      }
    },

    async enableUser(enabled = true) {
      if (!this.itemSelected) {
        return;
      }
      const { data } = await services.users.enableUser(this.itemSelected.uid, enabled);
      this.actionDialog = false;
      this.updateUserStatus(data);
      if (enabled) {
        this.setSnack('', 'Successfully enabled user');
      } else {
        this.setSnack('', 'Successfully disabled user');
      }
    },
    shouldRenderStatusActionBtn(item) {
      return item.status === 'Active' || item.status === 'Login Disabled';
    },
    deletePgUser(payload) {
      this.deleteUser(payload)
        .then(() => {
          this.setSnack('', 'Successfully deleted user');
          this.actionDialog = false;
        })
        .catch(() => {
          this.setSnack('error', 'Failed to delete user');
          this.actionDialog = false;
        });
    },
    deleteItem() {
      if (!this.itemSelected) {
        this.actionDialog = false;
        return;
      }

      const payload = {
        uid: this.itemSelected.firestore_uid,
        user_id: this.itemSelected.uid,
        company_id: this.currentCompany.cid,
        cid: this.currentCompany.firestore_cid,
      };

      // Rather than actually deleting the user from Firebase auth,
      // it will disable the authentication user
      services.users.delete_auth(this.itemSelected.firestore_uid)
        .then(() => {
          // This will delete the user from Firestore and switch active to false on Titan
          this.deletePgUser(payload);
        }).catch((err) => {
          console.log('error', err.response?.data.code);
          if (err.response?.data.code === 'auth/user-not-found') {
            this.deletePgUser(payload);
          }
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
        this.setUserError({});
      });
    },
    async save() {
      this.loading = true;
      const isFormValid = this.$refs.createForm.validate();
      if (!isFormValid) return;

      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        const authPayload = {
          displayName: `${this.editedItem.firstName} ${this.editedItem.lastName}`,
          email: this.editedItem.email,
        };
        if (this.editedItem.country !== '') {
          authPayload.phone = this.editedItem.phone;
          authPayload.country = this.editedItem.country;
        }

        const existedUser = this.employees.find((user) => user.email === authPayload.email);
        if (existedUser && existedUser.status === 'Login Disabled') {
          this.dialogTitle = 'User Already exists';
          this.dialogType = 'enable';
          this.itemSelected = existedUser;
          this.actionDialog = true;
          this.close();
          return;
        }
        try {
          const res = await services.users.create_auth(authPayload);
          const userInfo = res.data;
          const userPayload = {
            company_id: this.currentCompany.cid,
            cid: this.currentCompany.firestore_cid,
            data: {
              name: userInfo.displayName,
              firstName: userInfo.displayName.split(' ')[0],
              lastName: userInfo.displayName.split(' ')[1],
              email: userInfo.email,
              adminStatus: true,
              sid: '',
              uid: userInfo.uid,
              teams: [],
              status: 'not_registered',
            },
          };
          if (userInfo.phoneNumber) {
            userPayload.data.phone = userInfo.phoneNumber;
            userPayload.data.country = this.editedItem.country;
          }

          const res2 = await this.createUser(userPayload);
          this.setSnack('', `Successfully created account for ${res2.data.name}`);
          const url = `${window.location.origin}/#/register/${res2.data.uid}`;
          const res3 = await services.auth.registrationEmail(res2.data.email, res2.data.name, url);
          if (res3.status === 200) {
            this.setSnack('success', `An email has been sent to ${res2.data.name} to complete the registration`);
            this.close();
          }
        } catch (err) {
          const { details } = err.response.data.body;
          this.setUserError(details);
        }
      }
    },
  },
  computed: {
    ...mapGetters(['loadingUsers', 'currentCompany', 'userError']),
    formTitle() {
      return this.editedIndex === -1 ? 'New User' : 'Edit User';
    },
  },
  mounted() {
    this.countries = [...countries];
  },
};
</script>
