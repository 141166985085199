<template>
    <div class="create-project">
      <v-card>
        <v-container>
          <v-card-title class="headline" id="create-projects-title">
            Create Project
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text id="create-projects-form">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  dense
                  v-model="newProject.name"
                  label="Project Name"
                  outlined
                  id="project-name-input"
                  :error-messages="errors.name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  dense
                  prepend-icon="mdi-map-marker"
                  v-model="newProject.location"
                  outlined
                  id="project-location-input"
                  label="Location"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="6">
                    <v-textarea
                      dense
                      v-model="newProject.description"
                      label="Project Description"
                      outlined
                      id="project-description-input"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6">
                    <DatePicker
                      label="Inspection Date"
                      v-on:dateInput="getDate"
                      :iDate="newProject.date"
                      :error="errors.date"
                      id="project-date-input"
                    />
                    <v-select
                      dense
                      :items="teamItems"
                      item-text="name"
                      item-value="tid"
                      id="project-team-input"
                      outlined
                      prepend-icon="mdi-account-multiple"
                      label="Teams"
                      multiple
                      chips
                      :value="newProject.teams"
                      @change="teamsChangeHandler"
                      :loading="loadingTeams"
                      return-object
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <!-- make date picker -->
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :value="newProject.first_reviewer"
                  @change="reviewerChangeHandler"
                  item-text="name"
                  id="project-reviewer-input"
                  :loading="loadingUsers"
                  return-object
                  label="Select First Reviewer"
                  :items="reviewers"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                  outlined
                  dense
                  :items="['Transmission', 'Distribution', 'Other']"
                  label="Project Type"
                  id="project-type-input"
                  v-model="newProject.project_type"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="close-btn"
              id="close-project-form-btn"
              @click="close"
              text
            >Close</v-btn>
            <v-btn
              text
              color="primary"
              class="save-btn"
              id="create-project-btn"
              @click="onCreateProject"
            >Create New Project</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DatePicker from '@components/common/DatePicker.vue';
import services from '@services';

export default {
  name: 'CreateProjectForm',
  props: ['cid'],
  components: {
    DatePicker,
  },
  data: () => ({
    newProject: {
      name: '',
      description: '',
      location: '',
      teams: [],
      cid: '',
      date: '',
      first_reviewer: {},
      project_type: '',
      prototyping: false,
    },
    reviewers: [],
    teamItems: [],
    loadingUsers: false,
    loadingTeams: false,
    errors: {
      name: '',
      date: '',
    },
  }),
  methods: {
    ...mapActions(['createProject', 'createFolder']),
    getDate(date) {
      this.newProject.date = date;
    },
    teamsChangeHandler(teams) {
      this.newProject.teams = teams.map((team) => ({
        tid: team.tid,
        firestore_tid: team.firestore_tid,
      }));
    },
    reviewerChangeHandler(reviewer) {
      this.newProject.first_reviewer = {
        uid: reviewer.uid,
        firestore_uid: reviewer.firestore_uid,
        name: reviewer.name,
        email: reviewer.email,
        phone: reviewer.phone,
      };
    },
    clearForm() {
      this.newProject.name = '';
      this.newProject.description = '';
      this.newProject.location = '';
      this.newProject.teams = [];
      this.newProject.date = '';
      this.newProject.first_reviewer = {};
    },
    close() {
      this.$emit('closeDialog', false);
    },
    displayProjectCreationSuccess(projectName) {
      const message = `${projectName} has been successfully created`;
      this.$emit('createProjectSuccess', message);
    },
    onCreateProject() {
      const payload = {
        cid: this.currentCompany.firestore_cid,
        company_id: this.currentCompany.cid,
        data: {
          name: this.newProject.name,
          description: this.newProject.description,
          location: this.newProject.location,
          teams: this.newProject.teams,
          date: this.newProject.date,
          project_type: this.newProject.project_type,
          prototyping: this.newProject.prototyping,
          first_reviewer: this.newProject.first_reviewer,
        },
      };

      this.createProject(payload)
        .then(async (res) => {
          this.displayProjectCreationSuccess(res.data.name);
          this.clearForm();
          const { customFolder } = this.currentCompany.config;
          const structureName = (customFolder) || 'Miscellaneous';
          const folderPayload = {
            company_id: this.currentCompany.cid,
            project_id: res.data.pid,
            data: {
              pid: res.data.firestore_pid,
              cid: this.currentCompany.firestore_cid,
              structure: {
                name: structureName,
              },
            },
          };
          await this.createFolder(folderPayload);
          this.close();
          Object.keys(this.errors).forEach((field) => {
            this.errors[field] = '';
          });
        })
        .catch((err) => {
          if (err.response.data.name) this.errors.name = err.response.data.name;
          if (err.response.data.date) this.errors.date = err.response.data.date;
        });
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentUser']),
  },
  created() {
    this.loadingUsers = true;
    this.loadingTeams = true;

    services.users.all(`company_id=${this.currentCompany.cid}`)
      .then((uRes) => {
        this.reviewers = [...uRes.data];
        this.loadingUsers = false;
      })
      .catch(() => {
        this.loadingUsers = false;
      });

    services.teams.all(`company_id=${this.currentCompany.cid}`)
      .then((tRes) => {
        this.teamItems = [...tRes.data];
        this.loadingTeams = false;
      })
      .catch(() => {
        this.loadingTeams = false;
      });
  },
};
</script>
