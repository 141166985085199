/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { find } from 'lodash';
import { getW3C } from '@components/annotations/annotorious';
import helpers from '@methods/helpers';
import { baseURL } from '@utils/endpoint';
import services from '@services';
import platformBackendClient from '@backend-clients/platform-backend';
import { setImage } from '@utils/map/session-storage';
// http://34.117.182.46

const state = {
  images: [],
  processedImages: [],
  image: {},
  tasks: [],
  selected: [],
  annotations: [],
  annotationEdits: [],
  filters: [],
  filter: { type: '' },
  processing: false,
  uploading: false,
  loading: false,
  loadingImage: false,
  amount: 0,
  lazyLoadVisible: 3,
  lazyLoadPointer: 0,
  notification: { success: false, message: '' },
  error: { caption: '', notes: '' },
  cancelTokens: [],
  labelFilter: [],
  editing: false,
  modified: {
    caption: '',
    latitude: 0,
    longitude: 0,
    severity: '',
    priority: '',
    reviewed: false,
  },
  zeroProcessedImages: false,
  insightProcessedImages: [],
  filteredImagesByFault: [],
  imagesByFilter: [],
  companyFolders: [],
  isFromOriginalTab: false,
  severityFilterClicked: false,
  totalUploadPercent: 0,
  setCurrentImage: false,
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allImages: (state) => state.images.filter((e) => e.isDeleted === false),
  totalUploadPercent: (state) => state.totalUploadPercent,
  processedImagesR: (state) => state.processedImages.filter((e) => e.isDeleted === false),
  insightProcessedImages: (state) => state.insightProcessedImages,
  currentImage: (state) => state.image,
  currentAnnotations: (state) => state.annotations,
  processTasks: (state) => state.tasks,
  modifiedAnnotations: (state) => state.annotationEdits,
  selectedImageList: (state) => state.selected,
  imagesBeenProcessed: (state) => state.images.filter((image) => image.processedImageUrl),
  imagesNotProcessed: (state) => state.images.filter((image) => image.process_tracking.length < 1),
  loadingImages: (state) => state.loading,
  loadingCurrentImage: (state) => state.loadingImage,
  currentImageFilter: (state) => state.filter,
  processingImage: (state) => state.processing,
  uploadingImages: (state) => state.uploading,
  amountImages: (state) => state.amount,
  imageNotification: (state) => state.notification,
  lazyLoadVisible: (state) => state.lazyLoadVisible,
  lazyLoadPointer: (state) => state.lazyLoadPointer,
  imageError: (state) => state.error,
  currentLabelFilter: (state) => state.labelFilter,
  currentSeverityFilters: (state) => state.filters,
  editingImage: (state) => state.editing,
  modifiedImage: (state) => state.modified,
  zeroProcessedImages: (state) => state.zeroProcessedImages,
  filteredImagesByFault: (state) => state.filteredImagesByFault,
  imagesByFilter: (state) => state.imagesByFilter.filter((e) => e.isDeleted === false),
  companyFolders: (state) => {
    const reviewed = state.companyFolders.filter((folder) => {
      if (!folder.second_reviewer) return false;

      return folder.second_reviewer.reviewed;
    });

    const unreviewed = state.companyFolders.filter((folder) => {
      if (!folder.second_reviewer) return true;

      return !folder.second_reviewer.reviewed;
    });

    switch (state.filter) {
      case 'reviewed':
        return reviewed;
      case 'not_reviewed':
        return unreviewed;
      default:
        return state.companyFolders;
    }
  },
  isFromOriginalTab: (state) => state.isFromOriginalTab,
  severityFilterClicked: (state) => state.severityFilterClicked,
  setCurrentImage: (state) => state.setCurrentImage,
};

const actions = {
  setIsFormOriginalTab({ commit }, value) {
    commit('set_is_from_original_tab', value);
  },
  setUploadPercent({ commit }, value) {
    commit('set_upload_percent', value);
  },

  setSeverityFilterClicked({ commit }, value) {
    commit('set_severity_filter_clicked', value);
  },
  setFilteredImagesByFault({ commit }, value) {
    commit('set_filtered_images_by_fault', value);
  },
  filterPImages({ commit }, value) {
    const { filters, pImages } = value;
    const newPImages = pImages.filter((img) => filters.includes(img.process_tracking[0].severity));
    commit('set_insight_processed_images', newPImages);
  },
  // Saves images to Datastore
  // eslint-disable-next-line no-unused-vars
  createImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.create(payload.company_id, payload.project_id, payload.data)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Saves images to Datastore but don't set
  // eslint-disable-next-line no-unused-vars
  saveImages({ commit }, images) {
    return new Promise((resolve, reject) => {
      platformBackendClient.post(`${baseURL}/api/image/save_images`, images)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  // Fetches only images pertaining to the project being viewed
  getImagesByProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.getByProject(payload.cid, payload.pid)
        .then((res) => {
          commit('fetch_images', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  getImagesByProjectAndFolder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.getByProjectAndFolder(payload.pid, payload.cid, payload.folderPath)
        .then((res) => {
          const processedImages = res.data.filter((image) => image.processedImageUrl);
          commit('fetch_processed_images', processedImages);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  getImagesByFolder({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.getByFolder(payload.cid, payload.pid, payload.folder)
        .then((res) => {
          commit('fetch_images', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  // Fetches only processed images pertaining to the project being viewed
  getProcessedImagesByProject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.getProcessedByProject(payload)
        .then((res) => {
          if (res.data.message !== 'No processed images') {
            commit('fetch_processed_images', res.data.imagesReturned);
            commit('set_company_folders', res.data.companyFolders);
            commit('set_zero_processed_images', false);
          } else {
            commit('set_zero_processed_images', true);
          }
          resolve(res.data);
        })
        .catch((err) => reject(err));
    });
  },

  // Fetches all images a company owns
  getImagesByCompanyId({ commit }, cid) {
    commit('set_loading_images', true);
    return new Promise((resolve, reject) => {
      services.images.getByCompany(cid)
        .then((res) => {
          commit('fetch_images', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  // Fetches all images within projects that are sectioned off by teams
  getImagesByProjectIds({ commit }, payload) {
    commit('set_loading_images', true);

    return new Promise((resolve, reject) => {
      services.images.getByProjects(payload)
        .then((res) => {
          commit('fetch_images', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  // Fetches a single image
  getImage({ commit }, payload) {
    commit('set_loading_image');

    return new Promise((resolve, reject) => {
      services.images.get(payload)
        .then((res) => {
          commit('fetch_image', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  updateImageAnnotation({ commit }, payload) {
    return services.images.updateImageAnnotation(
      payload.company_id,
      payload.project_id,
      payload.id,
      payload.update,
    )
      .then((res) => {
        commit('update_image', res.data);
        return res;
      })
      .catch((err) => err);
  },

  // Updates Datastore entry for image
  async updateImage({ commit }, {
    image,
    cid,
    pid,
    iid,
  }) {
    return new Promise((resolve, reject) => {
      services.images.update(cid, pid, iid, image)
        .then((res) => {
          commit('update_image', res.data);
          commit('fetch_image', res.data);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },

  localUpdateImage({ commit }, image) {
    commit('update_image', image);
  },

  deleteImages({ commit }, payload) {
    return new Promise((resolve, reject) => {
      services.images.delete(
        payload.company_id,
        payload.project_id,
        payload.cid, // Will eventually get rid of cid and
        payload.pid, // pid when we discontinue Datastore completely
        payload.images,
      )
        .then((res) => {
          commit('delete_images', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  // Processes a list of images
  processImages({ commit }, images) {
    const { processData, cancelToken } = images;
    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'dev':
        processData.namespace = 'appengine-test';
        break;
      case 'stage':
        processData.namespace = 'appengine-stage';
        break;
      case 'main':
        processData.namespace = '';
        break;
      case 'mig-prod':
        processData.namespace = '';
        break;
      default:
        processData.namespace = 'appengine-test';
        break;
    }

    commit('set_processing', true);

    const options = {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
      cancelToken: cancelToken.token,
    };

    return new Promise((resolve, reject) => {
      services.ml.process(processData, options)
        .then((res) => {
          resolve(res);
          commit('update_images', res.data);
        })
        .catch((err) => reject(err));
    });
  },

  // Starts the batch processing
  startBatchProcessing({ commit }, images) {
    const { processData, cancelToken } = images;

    switch (process.env.VUE_APP_ENVIRONMENT) {
      case 'dev':
        processData.namespace = 'appengine-test';
        break;
      case 'stage':
        processData.namespace = 'appengine-stage';
        break;
      case 'main':
        processData.namespace = '';
        break;
      case 'mig-prod':
        processData.namespace = '';
        break;
      default:
        processData.namespace = 'appengine-test';
        break;
    }

    const options = {
      headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
      cancelToken: cancelToken.token,
    };

    return new Promise((resolve, reject) => {
      services.ml.batch_process(processData, options)
        .then((res) => {
          resolve(res);
          commit('set_process_tasks', res.data);
        })
        .catch((err) => reject(err));
    });
  },

  // Sets the currentImage getter
  async setCurrentImage(store, image) {
    if (store.getters.setCurrentImage) {
      return;
    }
    console.log(`Setting image ${image.filename}`);
    store.commit('is_setting_image', true);
    store.commit('set_current_image', image);
    setImage(image.filename);

    // Set annotations within the image
    const hasAnnotations = image.process_tracking && image.process_tracking.length > 0;
    if (hasAnnotations) {
      // Get the most up to date processed image data at the end of process_tracking
      const { length } = image.process_tracking;
      const processedImage = image.process_tracking[length - 1];
      // const { regions, labels } = processedImage;
      const l = (processedImage.labels) ? processedImage.labels : [];
      const r = (processedImage.regions) ? processedImage.regions : {};

      let labels;
      let regions;

      if (store.getters.companyHas('project_typing')) {
        const projectType = store.getters.currentProject.project_type;

        // If project_type is 'Distribution, then filter out Vegetation
        if (projectType && projectType === 'Distribution') {
          const labelIndex = l.findIndex((label) => (typeof label === 'string' ? label.includes('Vegetation Encroachment') : label.label.includes('Vegetation Encroachment')));
          const labelIndexes = [];
          l.forEach((label, index) => {
            const currentLabel = typeof label === 'string' ? label : label.label;
            if (currentLabel.includes('Vegetation Encroachment')) labelIndexes.push(`${index}`);
          });

          if (labelIndex !== -1) {
            labels = l.filter((label) => (typeof label === 'string' ? !label.includes('Vegetation Encroachment') : !label.label.includes('Vegetation Encroachment')));

            regions = Object.keys(r)
              .filter((key) => !labelIndexes.includes(key))
              .reduce((obj, key) => {
                obj[key] = r[key];
                return obj;
              }, {});
          } else {
            // If none of them are Vegetation Encroachment, then set labels to l and regions to r
            labels = l;
            regions = r;
          }
        } else {
          // If it's Transmission type,
          // then set labels to l and regions to r, since we include Vegetation Encroachment
          labels = l;
          regions = r;
        }
      } else {
        // If the instance does not even have project_typing, then set labels to l and regions to r
        labels = l;
        regions = r;
      }

      // Convert regions object into an array of bounding box regions
      const bboxRegions = Object.values(regions);

      // Convert the bounding box regions into xywh positionings
      const positionings = bboxRegions.map((region) => {
        const coordinates = [...region];

        if (coordinates.length !== 4) return coordinates;

        const width = coordinates[3] - coordinates[1];
        const height = coordinates[2] - coordinates[0];

        return [coordinates[1], coordinates[0], width, height];
      });

      // Convert positionings into W3C format
      const w3c = positionings.map((region, index) => {
        let currentLabel;
        let severity = 'N/A';
        let model;
        if (typeof (labels[index]) === 'string') {
          currentLabel = labels[index];
        } else {
          currentLabel = labels[index].label;
          severity = labels[index].severity;
          model = labels[index].from_model;
        }

        let labelData = find(
          store.rootGetters.flatProjectLabels, (abbr) => abbr.faultType === currentLabel,
        );

        if (labelData) {
          labelData.regions = region;
          if (severity !== 'N/A') {
            labelData.severity = severity;
          }
        } else {
          let faultType;
          if (typeof (labels[index]) === 'string') {
            const [a, ...splitedLabel] = currentLabel.split(':');
            if (splitedLabel.length === 0) {
              faultType = a;
            } else {
              faultType = splitedLabel.pop();
              severity = a;
            }
          } else {
            faultType = currentLabel;
            severity = labels[index].severity;
          }
          if (!['None', 'Low', 'Medium', 'High'].includes(severity)) {
            const labelFault = find(
              store.rootGetters.flatProjectLabels, (abbr) => abbr.faultType === faultType,
            );
            if (labelFault) {
              severity = labelFault.severity;
            }
          }
          labelData = {
            faultType,
            abbreviation: 'N/A',
            severity,
            regions: region,
          };
        }
        labelData.selectorType = region.length === 4 ? 'FragmentSelector' : 'SvgSelector';
        labelData.from_model = model;
        return getW3C(labelData, image.originalImageUrl);
      });

      store.commit('set_loading_image', false);
      store.commit('set_annotations', w3c);
      store.commit('set_modified_annotations', w3c);
    } else {
      store.commit('set_annotations', []);
      store.commit('set_modified_annotations', []);
    }
    store.commit('is_setting_image', false);
  },
  // Sets the current annotations
  setCurrentAnnotations({ commit }, annotations) { commit('set_annotations', annotations); },

  // Sets the pointer of image picker
  setLazyLoadPointer({ commit }, to) {
    commit('set_lazy_load_pointer', to);
  },

  // Sets the selected image list from ImagePicker.vue
  setSelectedImages({ commit }, images) { commit('select_images', images); },

  // Sets uploading state
  setUploading({ commit }, uploading) { commit('set_uploading', uploading); },

  // Sets new amount of images to get out of the NoImages page
  setNewImageAmount({ commit }, imgList) { commit('set_amount', imgList); },

  // Sets loading state for images
  setImagesLoading({ commit }, loading) { commit('set_loading_images', loading); },

  // Sets loading state for one specific image
  setLoadingImage({ commit }, loading) { commit('set_loading_image', loading); },

  // Sets the new initial amount of processed images once it's done
  setAmountProcessed({ commit }, num) { commit('set_process_amount', num); },

  // Sets editing image state
  editImageFields({ commit }, edit) { commit('set_edit_image', edit); },

  // Sets modified image states
  setModifiedImage({ commit }, field) { commit('set_modified_image', field); },

  // Sets a list of images that are about to be duplicated in processing
  setImageError({ commit }, error) { commit('image_error', error); },
  setProcessingImages({ commit }, processing) { commit('set_processing', processing); },
  setRegions({ commit }, annotations) { commit('set_modified_annotations', annotations); },
  finishImageLoading({ commit }) { commit('finish_loading_images'); },
  finishProcessingImages({ commit }) { commit('set_processing', false); },
  setImageDateFilter({ commit }, filterData) {
    const { type } = filterData;
    if (type !== 'betweenDates') {
      switch (type) {
        case 'oneDay':
          filterData.start = helpers.oneDayAgo().subtract(1, 'days');
          filterData.end = helpers.today().endOf('day');
          break;
        case 'oneWeek':
          filterData.start = helpers.oneWeekAgo().subtract(1, 'days').endOf('day');
          filterData.end = helpers.today().endOf('day');
          break;
        case 'oneMonth':
          filterData.start = helpers.oneMonthAgo().subtract(1, 'days').endOf('day');
          filterData.end = helpers.today().endOf('day');
          break;
        default: break;
      }
    } else {
      filterData.start = helpers.formatStartDate(filterData.start);
      filterData.end = helpers.formatEndDate(filterData.end);
    }
    commit('set_image_filter', filterData);
  },

  // label filtering
  addLabelFilter({ commit }, label) { commit('add_label_filter', label); },
  removeLabelFilter({ commit }, label) { commit('remove_label_filter', label); },
  setLabelFilter({ commit }, labels) { commit('set_label_filter', labels); },
  emptyLabelFilter({ commit }) { commit('set_label_filter', []); },

  // severity filteriing
  // addSeverityFilter({ commit }, severity) { commit('add_severity_filter', severity); },
  // removeSeverityFilter({ commit }, severity) { commit('remove_severity_filter', severity); },
  setSeverityFilter({ commit }, severities) {
    if (severities.includes(undefined)) commit('set_severity_filter', []);
    else commit('set_severity_filter', severities);
  },
  emptySeverityFilter({ commit }) { commit('set_severity_filter', []); },

  // Cancel Token actions
  addCancelToken({ commit }, cToken) { commit('add_cancel_token', cToken); },
  clearCancelTokens({ commit }) { commit('clear_cancel_tokens'); },
  applyCancelTokens({ state }) {
    state.cancelTokens.forEach((cancelToken) => {
      cancelToken.cancel('Image Processing Cancelled.');
    });
  },
  setCompanyFolders({ commit }, payload) {
    commit('set_company_folders', payload);
  },
  async updateAiFeedBack({ commit }, { query, aiFeedback }) {
    await services.images.updateAiFeedback(query, aiFeedback);
    commit('set_aiFeedback', aiFeedback);
  },
};

const mutations = {
  is_setting_image: (state, status) => {
    state.setCurrentImage = status;
  },
  // Sets the snackbar notifier for image related actions
  notification_image: (state, notification) => { state.notification = notification; },

  // Setters
  set_loading_images: (state, loading) => { state.loading = loading; },
  set_upload_percent: (state, percent) => { state.totalUploadPercent = percent; },
  set_filtered_images_by_fault: (state, images) => { state.filteredImagesByFault = images; },
  set_loading_image: (state, loading) => { state.loadingImage = loading; },
  set_amount: (state) => { state.amount = state.images.length; },
  set_uploading: (state, status) => { state.uploading = status; },
  set_current_image: (state, image) => {
    state.image = image;
    // eslint-disable-next-line max-len
    state.images = state.images.map((currentImage) => (
      (image.id === currentImage.id)
        ? image : currentImage));
    state.loadingImage = false;
  },
  set_insight_processed_images: (state, newPImages) => {
    state.insightProcessedImages = newPImages;
  },
  set_aiFeedback: (state, feedback) => {
    state.image.ai_feedback = feedback;
    state.image = { ...state.image };
    state.images = state.images.map((img) => (img.id === state.image.id ? state.image : img));
  },
  set_processing: (state, processing) => { state.processing = processing; },
  set_images_by_filter: (state, images) => { state.imagesByFilter = images; },
  set_image_filter: (state, filter) => { state.filter = filter; },
  set_process_amount: (state, numberOfProcessed) => { state.amount = numberOfProcessed; },
  finish_loading_images: (state) => { state.loading = false; },
  select_images: (state, images) => { state.selected = images; },
  image_error: (state, error) => { state.error = error; },
  set_annotations: (state, annotations) => { state.annotations = annotations; },
  set_modified_annotations: (state, annotations) => { state.annotationEdits = annotations; },
  set_process_tasks: (state, tasks) => { state.tasks = tasks; },
  set_processing_progress: (state, progress) => { state.progress = progress; },
  get_processing_progress: (state, progress) => { state.progress = progress; },
  set_lazy_load_pointer: (state, to) => { state.lazyLoadPointer = to; },
  set_label_filter: (state, filter) => { state.labelFilter = filter; },
  set_severity_filter: (state, filter) => { state.filters = filter; },
  set_edit_image: (state, edit) => { state.editing = edit; },
  set_modified_image: (state, field) => {
    state.modified[field.type] = field.value;
  },
  set_company_folders: (state, folders) => {
    state.companyFolders = folders;
  },
  set_is_from_original_tab: (state, value) => {
    state.isFromOriginalTab = value;
  },
  // CRUD
  upload_image: (state, image) => { state.images.push(image); },
  create_image: (state, image) => { state.images.push(image); },
  set_zero_processed_images: (state, value) => { state.zeroProcessedImages = value; },
  create_images: (state, images) => {
    if (state.images.length === 0) state.images = images;
    else state.images.unshift(...images);
  },
  fetch_image: (state, image) => {
    state.image = image;
    state.loadingImage = false;
  },
  fetch_images: (state, images) => {
    state.images = images;
    state.loading = false;
  },
  fetch_processed_images: (state, images) => {
    state.processedImages = images;
    state.loading = false;
  },
  delete_image: (state, id) => { state.images = state.images.filter((image) => image.id !== id); },
  delete_images: (state, images) => {
    images.forEach((image) => {
      state.images = state.images.filter((img) => img.id !== image);
    });
    const selected = state.images.find((img) => img.id === state.image.id);
    if (!selected && state.images.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      state.image = state.images[0];
    }
  },
  update_image: (state, newImage) => {
    const index = state.images.findIndex((image) => image.id === newImage.id);
    if (index !== -1) {
      state.images.splice(index, 1, newImage);
      state.image = newImage;
    }
  },
  update_images: (state, images) => {
    images.forEach((newImage) => {
      const index = state.images.findIndex((image) => image.id === newImage.id);
      if (index !== -1) state.images.splice(index, 1, newImage);
    });
  },
  add_label_filter: (state, label) => { state.labelFilter = [...state.labelFilter, label]; },
  remove_label_filter: (state, label) => {
    state.labelFilter = state.labelFilter.filter((lf) => lf !== label);
  },

  add_severity_filter: (state, severity) => {
    state.filters = [...state.filters, severity];
  },
  remove_severity_filter: (state, severity) => {
    state.filters = state.filters.filter((lf) => lf !== severity);
  },
  set_severity_filter_clicked: (state, severityClicked) => {
    state.severityFilterClicked = severityClicked;
  },

  // Cancel Token Mutations
  add_cancel_token: (state, cToken) => { state.cancelTokens.push(cToken); },
  clear_cancel_tokens: (state) => { state.cancelTokens = []; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
