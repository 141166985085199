<template>
  <v-container fluid id="projects-table-content">
    <v-data-table
      :headers="headers"
      :items="projects"
      :items-per-page="10"
      :search="currentProjectSearch"
      no-results-text="Unable to find any projects with that name"
      class="elevation-1"
    >
    <!-- T A B L E  H E A D E R -->
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="table-title">Projects</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-dialog v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                :disabled="isTrial"
                id="create-project-btn"
              ><v-icon>add</v-icon></v-btn>
            </template>
            <CreateProjectForm v-on:closeDialog="close" :cid="currentCompany.cid" />
          </v-dialog>
          <v-dialog v-model="deleteDialog" max-width="700">
            <!-- Delete project confirmation modal -->
            <v-card class="delete-project-confirm">
              <v-card-title class="headline" id="delete-project-title">
                Are you sure you want to delete the project with name: {{ project.name }}?
              </v-card-title>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="closeDelete"
                  id="close-delete-btn"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="deleteProjectConfirm"
                  id="confirm-delete-btn"
                >OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <!-- T E A M S  C O L U M N  T E M P L A T E -->
      <template v-slot:[`item.teams`]="{ item }">
        <v-chip
          small
          class="ma-2"
          color="secondary"
          v-for="(team, index) in getTeams(item)"
          :key="index"
          :id="`team-${item.tid}`"
        >
          {{ team }}
        </v-chip>
      </template>
      <!-- A C T I O N S  C O L U M N  T E M P L A T E -->
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="view-project-btn"
              icon
              color="primary"
              v-on="on"
              v-bind="attrs"
              :to="`/project/${item.pid}`"
              :id="`view-project-${item.pid}-btn`"
            >
              <v-icon small>visibility</v-icon>
            </v-btn>
          </template>
          View Project
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              class="delete-project-btn"
              v-on="on"
              v-bind="attrs"
              @click="onDeleteProject(item)"
              :id="`delete-project-${item.pid}-btn`"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          Delete Project
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import CreateProjectForm from '@components/projects/CreateProjectForm.vue';
import dateFormat from '@utils/time-formats';

export default {
  name: 'ProjectsTable',
  components: {
    CreateProjectForm,
  },
  data: () => ({
    headers: [
      { text: 'Name', align: 'start', value: 'name' },
      { text: 'Created On', value: 'date' },
      { text: 'Description', value: 'description' },
      { text: 'Location', value: 'location' },
      { text: 'Teams', value: 'teams' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    project: {},
    dialog: false,
    deleteDialog: false,
    search: '',
  }),
  methods: {
    ...mapActions(['deleteProject', 'setNotification']),
    getTeams: (project) => project.teams.map((team) => team.name),
    close(shouldClose) { this.dialog = shouldClose; },
    onDeleteProject(item) {
      this.project = cloneDeep(item);
      this.deleteDialog = true;
    },
    closeDelete() {
      this.project = {};
      this.deleteDialog = false;
    },
    deleteProjectConfirm() {
      this.deleteProject({ cid: this.currentCompany.cid, pid: this.project.pid })
        .then(() => {
          this.setNotification({
            success: true,
            message: `Project ${this.project.name} has been deleted`,
            color: '',
          });
          this.deleteDialog = false;
        })
        .catch(() => {
          this.setNotification({
            success: true,
            message: `Failed to delete ${this.project.name}`,
            color: 'error',
          });
        });
    },
  },
  computed: {
    ...mapGetters(['isTrial', 'allProjects', 'currentCompany', 'currentProjectSearch', 'companyHas']),
    projects() {
      const projectList = [...this.allProjects];
      return projectList.map((project) => {
        const p = { ...project };
        p.date = dateFormat(p.date, 'DAY_DATE_TIME');
        return p;
      });
    },
  },
  mounted() {
    if (this.companyHas('poleByFolders')) {
      this.headers = [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Created On', value: 'date' },
        { text: 'Description', value: 'description' },
        { text: 'Location', value: 'location' },
        { text: 'Teams', value: 'teams' },
        { text: 'First Reviewer', value: 'first_reviewer.name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ];
    }
  },
};
</script>
