import { find } from 'lodash';
import { fabric } from 'fabric';
import abbreviations from '@components/images/abbreviations';

const imageViewHTML = (imgUrl) => {
  const head = `<style>
    #image {
      width: 60%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .zoomin {
      cursor: zoom-in;
    }
    .zoomout {
      cursor: zoom-out;
    }
    body {
      background-color: black;
    }
    .button {
      background-color: #1976d2;
      border: none;
      color: white;
      padding: 15px 30px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
    }
    .button:active {
      transform: scale(0.98);
      /* Scaling button to 0.98 to its original size */
      box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
      /* Lowering the shadow */
    }
    .topright {
      position: fixed;
      top: 10;
      right: 10;
    }
  </style>
  </head>`;

  const body = `<body>
  <img id="image" class="zoomin" crossOrigin="anonymous" src="${imgUrl}" onclick="zoom()"></img>
  <a href="${imgUrl}" download="buzz.png" class="button topright" id="dbutton">download</a>
  </body>`;

  const script = document.createElement('script');
  script.innerHTML = `function zoom() {
      var img = document.getElementById("image");
      var btn = document.getElementById("dbutton");
      if (img.className == 'zoomin') {
        img.style.width = '120%';
        img.className = 'zoomout';
        btn.style.visibility = 'hidden';
      } else {
        img.style.width = '60%';
        img.className = 'zoomin';
        btn.style.visibility = 'visible';
      }
    }`;
  return [head, body, script];
};

const annotations = (image) => {
  // Get the most up to date processed image data at the end of process_tracking
  const { length } = image.process_tracking;
  const processedImage = image.process_tracking[length - 1];
  const { regions, labels } = processedImage;

  // Convert regions object into an array of bounding box regions
  const bboxRegions = Object.values(regions);

  // Convert the bounding box regions into xywh positionings
  const positionings = bboxRegions.map((region) => {
    const coordinates = [...region];

    if (coordinates.length !== 4) return coordinates;

    const width = coordinates[3] - coordinates[1];
    const height = coordinates[2] - coordinates[0];
    return [coordinates[1], coordinates[0], width, height];
  });

  // Convert positionings into W3C format
  const annotes = [];
  positionings.forEach((region, index) => {
    let currentLabel;
    let severity = 'N/A';
    if (typeof (labels[index]) === 'string') {
      currentLabel = (labels[index].includes(':')) ? labels[index].split(':')[1] : labels[index];
    } else {
      currentLabel = labels[index].label;
      severity = labels[index].severity;
    }
    let labelData = find(abbreviations, (abbr) => abbr.faultType === currentLabel);
    if (labelData) {
      labelData.regions = region;
    } else {
      let faultType = currentLabel;
      const [a, ...splitedLabel] = currentLabel.split(':');
      if (['None', 'Low', 'Medium', 'High'].includes(a)) {
        severity = a;
        faultType = splitedLabel.join(' ');
      }
      labelData = {
        faultType,
        abbreviation: 'N/A',
        severity,
      };
    }
    annotes.push({
      ...labelData,
      severity,
      regions: region,
      selectorType: region.length === 4 ? 'FragmentSelector' : 'SvgSelector',
    });
  });
  return annotes;
};

export const annotateImage = (image) => {
  const hasProcessedUrl = image.proessedImageUrl !== null;
  const hasProcessTracking = image.process_tracking.length > 0;
  const hasAnnotations = hasProcessedUrl || hasProcessTracking;
  if (!hasAnnotations) return false;
  // eslint-disable-next-line no-unused-vars
  return new Promise((resolve, reject) => {
    const c = document.createElement('canvas');
    const i = new Image();
    i.src = image.originalImageUrl;
    i.crossOrigin = 'anonymous';
    i.onload = () => {
      const canvas = new fabric.Canvas(c, { width: i.width, height: i.height });
      const imgInstance = new fabric.Image(i);
      canvas.add(imgInstance);
      const severityColor = {
        None: 'green',
        Low: 'blue',
        Medium: 'yellow',
        High: 'red',
        null: 'violet',
        undefined: 'violet',
        '': 'violet',
      };
      annotations(image).forEach((anno) => {
        if (anno.selectorType === 'FragmentSelector') {
          canvas.add(
            new fabric.Rect({
              width: anno.regions[2],
              height: anno.regions[3],
              left: anno.regions[0],
              top: anno.regions[1],
              stroke: severityColor[anno.severity],
              strokeWidth: 10,
              fill: 'rgba(0,0,0,0)',
            }),
          );
          canvas.add(
            new fabric.Text(anno.faultType,
              {
                left: anno.regions[0],
                top: anno.regions[1],
                fontWeight: 'bold',
                textBackgroundColor: severityColor[anno.severity],
                fill: 'rgb(255,255,255)',
              }),
          );
        }

        if (anno.selectorType === 'SvgSelector') {
          const points = [];
          // eslint-disable-next-line no-plusplus
          for (let k = 0; k < anno.regions.length / 2; k++) {
            const j = 2 * k;
            points.push({
              x: anno.regions[j],
              y: anno.regions[j + 1],
            });
          }
          canvas.add(
            new fabric.Polygon(points, {
              stroke: severityColor[anno.severity],
              strokeWidth: 10,
              fill: 'rgba(0,0,0,0)',
            }),
          );
          canvas.add(
            new fabric.Text(anno.faultType,
              {
                left: anno.regions[0],
                top: anno.regions[1],
                fontWeight: 'bold',
                textBackgroundColor: severityColor[anno.severity],
                fill: 'rgb(255,255,255)',
              }),
          );
        }
      });
      resolve(canvas.toDataURL());
    };
  });
};

export const generateImageViewer = async (image) => {
  const imgUrl = await annotateImage(image);
  return imageViewHTML(imgUrl);
};
