<template>
  <v-card>
    <v-container fluid>
      <v-card-title class="headline" id="label-header">
        {{ label }}
        <v-spacer></v-spacer>
        <v-btn icon small @click="onCloseDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-container fluid>
          <v-data-table
            id="fault-list-table"
            :headers="headers"
            :items="images"
            class="elevation-1"
            :sort-by="['date']"
            :sort-desc="[true]"
            item-key="filename+date"
            item-class="filename"
            :items-per-page="10"
          >
            <template v-slot:[`item.date`]="{ item }">
              {{ formatDate(item.date) }}
            </template>
            <template v-slot:[`item.location[0]`]="{ item }">
              {{ formatLatitude(item.location[0]) }}
            </template>
            <template v-slot:[`item.location[1]`]="{ item }">
              {{ formatLongitude(item.location[1]) }}
            </template>
            <template v-slot:[`item.pid`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    :to="`/project/${item.project_id}`"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>fas fa-tasks</v-icon>
                  </v-btn>
                </template>
                Go To Project
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    small
                    color="primary"
                    :href="item.processedImageUrl"
                    target="_blank"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>image</v-icon>
                  </v-btn>
                </template>
                Go To {{ item.filename }}
              </v-tooltip>
            </template>
            <template v-slot:[`item.process_tracking`]="{ item }">
              <v-chip
                :dark="item.process_tracking.slice(-1)[0].severity !== 'Medium'"
                :color="chipColor(item.process_tracking.slice(-1)[0].severity).color"
                small
              >
                {{ item.process_tracking.slice(-1)[0].severity }}
              </v-chip>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="onCloseDialog">Close</v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { latitudeDMS, longitudeDMS } from '@methods/helpers';
import { generateImageViewer } from '@components/annotations/fabric-renderer';
import dateFormat from '@utils/time-formats';

export default {
  name: 'FaultSummaryTable',
  props: ['images', 'label'],
  data() {
    return {
      headers: [
        {
          text: 'File Name',
          value: 'filename',
          align: 'start',
          sortable: true,
        },
        {
          text: 'Structure Name',
          value: 'folder',
        },
        { text: 'Date Uploaded', value: 'date' },
        {
          text: 'Severity',
          value: 'process_tracking',
          sort(a, b) {
            const severityOrder = ['None', 'Low', 'Medium', 'High'];
            const [aProcessData] = a.slice(-1);
            const [bProcessData] = b.slice(-1);
            const aSeverity = aProcessData.severity;
            const bSeverity = bProcessData.severity;

            const aSeverityOrder = severityOrder.indexOf(aSeverity);
            const bSeverityOrder = severityOrder.indexOf(bSeverity);

            return aSeverityOrder - bSeverityOrder;
          },
        },
        {
          text: 'Latitude',
          value: 'location[0]',
          sortable: false,
        },
        {
          text: 'Longitude',
          value: 'location[1]',
          sortable: false,
        },
        {
          text: 'Go to',
          value: 'pid',
          sortable: false,
        },
      ],
      generating: false,
      footer: {
        showFirstLastPage: true,
        firstIcon: 'mdi-page-first',
        lastIcon: 'mdi-page-last',
        prevIcon: 'mdi-chevron-left',
        nextIcon: 'mdi-chevron-right',
        'items-per-page-options': [10, 50, 100, 200, 500],
      },
    };
  },
  methods: {
    onCloseDialog() {
      this.$emit('closeDialog', false);
    },
    chipColor(severity) {
      const colors = {
        High: 'red',
        Medium: 'yellow lighten-1',
        Low: 'green',
        None: 'blue',
      };
      return {
        color: colors[severity],
      };
    },
    formatDate: (date) => dateFormat(date, 'DAY_DATE_TIME'),
    formatLatitude: (latitude) => ((latitude) ? latitudeDMS(latitude) : 'N/A'),
    formatLongitude: (longitude) => ((longitude) ? longitudeDMS(longitude) : 'N/A'),
    async exportAnnotation(image) {
      this.generating = true;
      const [head, body, script] = await generateImageViewer(image);
      const newWin = window.open('', Date.now().toString());
      newWin.document.head.innerHTML = head;
      newWin.document.body.innerHTML = body;
      newWin.document.head.appendChild(script);
      this.generating = false;
    },
    onScroll(e) {
      // debounce if scrolling fast
      if (this.folders !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        this.timeout && clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
          const { scrollTop } = e.target;
          const rows = Math.ceil(scrollTop / this.rowHeight);

          this.start = rows + this.perPage > this.folders.length
            ? this.folders.length - this.perPage : rows;

          this.$nextTick(() => { e.target.scrollTop = scrollTop; });
        }, 20);
      }
    },
  },
};
</script>

<style scoped>
/* #fault-list-table {
  max-height: 450px;
  overflow: auto;
} */
</style>
