<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="allCompanies"
      class="elevation-1"
      :loading="loadingCompanies || updatingUser"
    >
      <template v-slot:item.createdAt="{ item }">
        <span>{{ dateFormat(item.createdAt, 'DAY_DATE') }}</span>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Company Instances</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on: dialog, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    color="primary"
                    class="mb-2"
                    v-bind="attrs"
                    v-on="{ ...tooltip, ...dialog }"
                    icon
                  >
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                Create Company Instance
              </v-tooltip>
            </template>
            <CreateInstanceForm :company="editedCompany" v-on:close="closeForm"/>
          </v-dialog>
          <v-dialog v-model="userDialog" max-width="750px">
            <v-card>
              <v-card-title class="headline">
                Create new User: {{ editedCompany.companyName }}
              </v-card-title>
              <v-divider></v-divider>
              <CreateUserForm v-on:close="closeUserForm" v-on:save="saveUser" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="editDialog" max-width="750px">
            <EditCompanyForm
              :company="editedCompany"
              @close="editDialog = false"
              :key="editedCompany.cid"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.finished`]="{ item }">
        <v-chip x-small :color="(item.finished) ? 'green' : 'secondary'" text-color="white">
          {{ (item.finished) ? 'Finished' : 'In Progress' }}
        </v-chip>
      </template>
      <template v-slot:[`item.provisioned`]="{item}">

        <v-icon v-if="item.provisioned" color="primary">
        mdi-check
      </v-icon>
        <v-progress-circular
        v-else
        size="20"
        indeterminate
        color="primary"
      ></v-progress-circular>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              :disabled="!item.provisioned"
              :color="(currentUser.cid === item.cid) ? 'primary': ''"
              v-on="on"
              v-bind="attrs"
              @click="swapInstance(item)"
            >
              <v-icon class="mr-2">
                {{ (currentUser.cid === item.cid) ? 'toggle_on' : 'toggle_off' }}
              </v-icon>
            </v-btn>
          </template>
          {{
            (currentUser.cid === item.cid)
            ? `Turn off ${item.companyName}` : `Switch to ${item.companyName} instance`
          }}
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="addUser(item)"
            >
              <v-icon class="mr-2">person_add</v-icon>
            </v-btn>
          </template>
          Add User
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              x-small
              color="primary"
              v-on="on"
              v-bind="attrs"
              @click="editClient(item)"
            >
              <v-icon class="mr-2">edit</v-icon>
            </v-btn>
          </template>
          Edit Client
        </v-tooltip>
      </template>
    </v-data-table>
    <div
      style="display: flex;
      flex-direction: row;
      justify-content: center; align-items: flex-start;">
      <div style="padding: 20px;text-align: center">
        <div>Frontend Release Info</div>
        <div>{{ hash }}</div>
        <div>{{
          dateFormat(releaseDate,'MONTH_DAY_YEAR_TIME')
        }}</div>
      </div>
      <div style="padding:20px;text-align: center">
        <div> Backend Release Info</div>
        <div>{{backendReleaseInfo.hash}}</div>
        <div>{{
          dateFormat(backendReleaseInfo.releaseDate,'MONTH_DAY_YEAR_TIME')
        }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import CreateInstanceForm from '@components/admin/CreateInstanceForm.vue';
import CreateUserForm from '@components/admin/CreateUserForm.vue';
import EditCompanyForm from '@components/admin/EditCompanyForm.vue';
import services from '@services';
import dateFormat from '@utils/time-formats';

export default {
  name: 'CompanyTable',
  components: {
    CreateInstanceForm,
    CreateUserForm,
    EditCompanyForm,
  },
  data: () => ({
    checkingStatus: false,
    checkCompanyStatus: null,
    dialog: false,
    backendReleaseInfo: {},
    hash: process.env.VUE_APP_GIT_HASH,
    releaseDate: process.env.VUE_APP_RELEASE_DATE,
    dialogDelete: false,
    userDialog: false,
    editDialog: false,
    headers: [
      { text: 'Company', value: 'companyName' },
      { text: 'Date Created', value: 'createdAt' },
      { text: 'Images Processed', value: 'images_processed' },
      { text: 'User Amount', value: 'employees.length' },
      { text: 'Model', value: 'ml_model' },
      { text: 'Provisioned', value: 'provisioned', align: 'center' },
      { text: 'Actions', value: 'actions' },
    ],
    editedCompany: {},
    errors: {},
  }),
  computed: {
    ...mapGetters(['loadingCompanies', 'allCompanies', 'currentUser', 'updatingCompany', 'updatingUser']),
  },
  methods: {
    dateFormat,
    ...mapActions(['updateUser', 'getCompany', 'updateJWTToken', 'createUser', 'setNotification', 'setUserError']),
    closeForm() {
      this.dialog = false;
    },
    swapInstance(company) {
      // Clone the user getter
      const user = cloneDeep(this.currentUser);

      // If the clicked company cid matches the currentUser cid,
      // then just retrieve the id of the company document
      // and reset it back to the original BuzzSolutions cid
      user.company_id = (company.cid === this.currentUser.cid)
        ? this.currentUser.originalCid : company.cid;
      user.cid = company.firestore_cid;

      const updates = {
        company_id: (company.cid === this.currentUser.cid)
          ? this.currentUser.cid : company.cid,
        cid: (company.firestore_cid === this.currentUser.firestore_cid)
          ? this.currentUser.firestore_cid : company.firestore_cid,
      };

      const payload = {
        user_id: this.currentUser.uid,
        uid: this.currentUser.firestore_uid,
        updates,
      };
      this.updateUser(payload)
        .then((res) => {
          this.updateJWTToken(res.data.uid)
            .then((decoded) => {
              this.getCompany(decoded.company_id);
              window.location.reload();
            });
        });
    },
    // Opens the "Create User Form"
    addUser(company) {
      this.editedIndex = this.allCompanies.indexOf(company);
      this.editedCompany = { ...company };
      this.userDialog = true;
    },
    editClient(client) {
      this.editedCompany = { ...client };
      this.editDialog = true;
    },
    // Closes the "Create User Form"
    closeUserForm() {
      this.userDialog = false;
    },
    // Saves User to DB
    async saveUser(newUser) {
      const authUser = {
        displayName: `${newUser.firstName} ${newUser.lastName}`,
        email: newUser.email,
        password: newUser.password,
        password2: newUser.password2,
      };

      try {
        const res = await services.users.create_auth(authUser);
        const userInfo = res.data;
        const user = {
          firstName: newUser.firstName,
          lastName: newUser.lastName,
          country: newUser.country,
          phone: newUser.phone,
          email: newUser.email,
          sid: '',
          uid: userInfo.uid,
          buzzAdmin: false,
          adminStatus: true,
          registered: true,
          name: `${newUser.firstName} ${newUser.lastName}`,
          teams: [],
          status: 'active',
        };

        const payload = {
          cid: this.editedCompany.firestore_cid,
          company_id: this.editedCompany.cid,
          data: user,
        };
        const res2 = await this.createUser(payload);

        if (res2.status === 200) {
          this.setNotification({
            message: `Successfully created user for ${this.editedCompany.companyName}`,
            success: true,
            color: '',
          });
          this.closeUserForm();
        }
      } catch (err) {
        const { details, message } = err.response.data.body;
        const errors = {};

        if (details.email) errors.email = details.email;
        else errors.email = '';

        if (details.firstName) errors.firstName = details.firstName;
        else errors.firstName = '';

        if (details.lastName) errors.lastName = details.lastName;
        else errors.lastName = '';

        if (details.phone) errors.phone = details.phone;
        else errors.phone = '';

        if (details.code) errors.email = message;
        this.setUserError(errors);
      }
    },
  },
  destroyed() {
    if (this.checkCompanyStatus) {
      clearInterval(this.checkCompanyStatus);
    }
  },
  async created() {
    try {
      this.checkCompanyStatus = setInterval(() => {
        if (this.checkingStatus) {
          return;
        }
        const pendingIds = this.allCompanies
          .filter(({ provisioned }) => !provisioned).map(({ cid }) => cid);

        const promises = pendingIds.map(async (id) => {
          const { data } = await services.companies.get(id);
          return data;
        });
        this.checkingStatus = true;
        Promise.all(promises).then((companies) => {
          companies.filter((company) => company.provisioned).forEach((company) => {
            this.$store.commit('update_company', company);
          });
          this.checkingStatus = false;
        }).catch((err) => {
          console.error(err);
        });
      }, 5000);
      const { data } = await services.release.get();
      this.backendReleaseInfo = data;
    } catch (err) {
      console.error(err);
    }
  },
};
</script>
