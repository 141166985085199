<template>
  <v-select
    outlined
    v-model="selectedSeverities"
    :items="severities"
    :loading="loadingImages"
    chips
    label="Filter Images By Severities"
    multiple
    clearable
    single-line
    @input="filterBySeverity"
  ></v-select>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilterBySeverity',
  data: () => ({
    severities: ['None', 'Low', 'Medium', 'High'],
    selectedSeverities: [],
  }),
  methods: {
    ...mapActions(['setDashboardFilters']),
    filterBySeverity() {
      this.setDashboardFilters({
        type: 'severities',
        value: this.selectedSeverities,
      });
    },
  },
  computed: {
    ...mapGetters(['dashboardFilters', 'loadingImages']),
  },
  mounted() {
    this.selectedSeverities = [...this.dashboardFilters.severities];
  },
};
</script>
