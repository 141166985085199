import { find } from 'lodash';
import { Annotorious } from '@recogito/annotorious';
import { v4 as uuidv4 } from 'uuid';
import widget from '@components/annotations/widget';
import menuWidget from '@components/annotations/menuWidget';

const severityFormatter = (abbreviation) => (annotation) => {
  const severityColor = {
    None: 'green',
    Low: 'blue',
    Medium: 'yellow',
    High: 'red',
  };

  const severityBody = annotation.bodies.find((b) => b.purpose === 'severity');
  const labelBody = annotation.bodies.find((b) => b.purpose === 'labeling');

  if (severityBody && severityColor[severityBody.value]) return { style: `stroke-width:3; stroke: ${severityColor[severityBody.value]}` };

  if (labelBody) {
    const labelData = find(abbreviation, (abbr) => abbr.faultType === labelBody.value);
    if (labelData && severityColor[labelData.severity]) return { style: `stroke-width:3; stroke: ${severityColor[labelData.severity]}` };
  }

  return { style: 'stroke-width:3; stroke: violet' };
};

export const Annotator = (imageToAnnotate, flatLabels, labels, isCategorized) => {
  const labelWidget = isCategorized ? menuWidget.menuWidget(labels) : widget.labeling(flatLabels);
  return new Annotorious({
    image: imageToAnnotate,
    widgets: [
      { widget: labelWidget, force: 'PlainJS' },
      { widget: widget.customLabeling, force: 'PlainJS' },
      { widget: widget.severity, force: 'PlainJS' },
    ],
    formatter: severityFormatter(flatLabels),
  });
};

export const ReadOnlyAnnotator = (imageToAnnotate, flatLabels) => new Annotorious({
  image: imageToAnnotate,
  readOnly: true,
  formatter: severityFormatter(flatLabels),
  widgets: [widget.labelDisplay],
});

export const getW3C = (labelData, url) => {
  const w3c = {
    '@context': 'http://www.w3.org/ns/anno.jsonld',
    id: `#${uuidv4()}`,
    type: 'Annotation',
    body: [
      { purpose: 'severity', type: 'TextualBody', value: labelData.severity },
      { purpose: 'abbreviation', type: 'TextualBody', value: labelData.abbreviation },
      { purpose: 'labeling', type: 'TextualBody', value: labelData.faultType },
    ],
    from_model: labelData.from_model,
  };

  if (labelData.selectorType === 'FragmentSelector') {
    w3c.target = {
      selector: {
        type: 'FragmentSelector',
        conformsTo: 'http://www.w3.org/TR/media-frags/',
        value: `xywh=pixel:${labelData.regions[0]},${labelData.regions[1]},${labelData.regions[2]},${labelData.regions[3]}`,
      },
      source: url,
    };
  }

  if (labelData.selectorType === 'SvgSelector') {
    w3c.target = {
      selector: {
        type: 'SvgSelector',
        value: `<svg><polygon points='${labelData.regions.join(',')}'></polygon></svg>`,
      },
      source: url,
    };
  }

  return w3c;
};

export const convertW3CToRegions = (annotation, abbreviation) => {
  const { body, target } = annotation;
  let abbrevs = 'N/A';
  let label = 'N/A';
  let severity = 'None';
  const labelBody = body.find((b) => b.purpose === 'labeling');
  if (labelBody) {
    const labelData = find(abbreviation, (abbr) => abbr.faultType === labelBody.value);
    if (labelData) {
      abbrevs = labelData.abbreviation;
      label = labelData.faultType;
      severity = labelData.severity;
    }
    const severityBody = body.find((b) => b.purpose === 'severity');
    severity = severityBody ? severityBody.value : severity;
    label = labelBody.value.trim() === '' ? 'N/A' : labelBody.value.trim();
    label = `${severity}:${label}`;
  } else {
    const severityBody = body.find((b) => b.purpose === 'severity');
    severity = severityBody ? severityBody.value : severity;
    label = `${severity}:${label}`;
  }

  function getFragmentsRegions(fragmentSelector) {
    const [, positioning] = fragmentSelector.split(':');
    const region = positioning.split(',').map((coordinate) => parseFloat(coordinate));

    return [region[1], region[0], region[1] + region[3], region[0] + region[2]];
  }

  function getSvgRegions(svgSelector) {
    const el = document.createElement('div');
    el.innerHTML = svgSelector;
    const [poly] = el.getElementsByTagName('polygon');
    const points = [];
    Object.keys(poly.points).forEach((key) => {
      points.push(poly.points[key].x);
      points.push(poly.points[key].y);
    });

    return points;
  }

  function getRegions(selector) {
    if (selector.type === 'SvgSelector') {
      return getSvgRegions(selector.value);
    }
    if (selector.type === 'FragmentSelector') {
      return getFragmentsRegions(selector.value);
    }
    return [];
  }

  const regions = getRegions(target.selector);

  const res = {
    label,
    abbreviation: abbrevs,
    regions,
    from_model: annotation.from_model,
  };
  return res;
};
