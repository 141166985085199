<template>
  <v-container fluid>
    <EmployeesTable title="Employees" :employees="employeeData" :cid="cid" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import EmployeesTable from '@components/company/EmployeesTable.vue';

export default {
  name: 'Employees',
  props: ['cid'],
  components: {
    EmployeesTable,
  },
  computed: {
    ...mapGetters(['allUsers']),
    employeeData() {
      const existingEmployees = this.allUsers.filter((e) => e != null);
      const formatStatus = (status) => status.split('_')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
      return existingEmployees.map((employee) => ({
        firstName: employee.firstName,
        lastName: employee.lastName,
        phone: employee.phone,
        email: employee.email,
        adminStatus: (employee.adminStatus) ? 'Admin' : 'User',
        teams: employee.teams,
        uid: employee.uid,
        firestore_uid: employee.firestore_uid,
        status: formatStatus(employee.status),
      }));
    },
  },
};
</script>
