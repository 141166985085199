<template>
  <v-card>
    <v-card-text>
      <v-container fluid>
        <v-row v-if="loadingImages">
          <v-col cols="6">
            <SkeletonLoader type="card"/>
          </v-col>
          <v-col cols="6">
            <SkeletonLoader type="card" />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="6">
            <ImageCard
              :url="currentImage.originalImageUrl"
              :compress_url="
                (currentImage.compressedUrl)
                  ? currentImage.compressedUrl
                  : currentImage.originalImageUrl"
            />
          </v-col>
          <v-col cols="6">
            <AnnotoriousAnalyticsRenderer
              :images="currentImage"
              :currentTopImage="currentImage"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <ImageNavigationButtons :images="images" />
      <v-spacer></v-spacer>
      <v-dialog
        v-model="annotateDialog"
        @click:outside="closeAnnotation"
        transition="dialog-bottom-transition"
        persistent
        fullscreen
      >
        <template v-slot:activator="{ on: dialog, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                small
                fab
                icon
                right
                color="primary"
                id="human-in-loop-btn-analytics"
                v-bind="attrs"
                v-on="{...tooltip, ...dialog}"
                reg="annotateBtn"
                @click="annotateDialog = true"
              >
                <v-icon>brush</v-icon>
              </v-btn>
            </template>
            Annotate
          </v-tooltip>
        </template>
        <HumanInTheLoop
          v-on:closeHIL="annotateDialog = false"
          :editMode="true"
        />
        <v-card>
          <router-view v-on:closeDialog="closeAnnotation"></router-view>
        </v-card>
      </v-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageNavigationButtons from '@components/dashboard/navigations/ImageNavigationButtons.vue';
import ImageCard from '@components/common/ImageCard.vue';
import AnnotoriousAnalyticsRenderer from '@components/annotations/AnnotoriousAnalyticsRendere.vue';
import HumanInTheLoop from '@views/annotations/HumanInTheLoop.vue';
import SkeletonLoader from '@components/common/loaders/insights/SkeletonLoader.vue';

export default {
  name: 'DashboardImage',
  props: ['images'],
  components: {
    ImageNavigationButtons,
    ImageCard,
    AnnotoriousAnalyticsRenderer,
    HumanInTheLoop,
    SkeletonLoader,
  },
  data: () => ({
    annotateDialog: false,
  }),
  methods: {
    closeAnnotation() {
      this.annotateDialog = false;
      if (this.fromEnlargeDialog) {
        this.$refs.imgViewBtn.$el.click();
      }
    },
  },
  computed: {
    ...mapGetters(['currentImage', 'loadingImages']),
    url() {
      return (this.currentImage.compressedUrl)
        ? this.currentImage.compressedUrl
        : this.currentImage.originalImageUrl;
    },
  },
};
</script>
