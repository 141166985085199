export default {
  conductor_good: { color: '', active: false },
  conductor_damaged: { color: '', active: false },
  connectors_corroded: { color: '', active: false },
  cotter_pin_missing_loose: { color: '', active: false },
  dampers_damaged: { color: '', active: false },
  glass_insulators_broken: { color: '', active: false },
  glass_insulators_contaminated: { color: '', active: false },
  glass_insulators_good: { color: '', active: false },
  ground_bonds_broken: { color: '', active: false },
  marker_balls: { color: '', active: false },
  misaligned_amor_grips: { color: '', active: false },
  misaligned_hardware: { color: '', active: false },
  misaligned_insulators: { color: '', active: false },
  nests: { color: '', active: false },
  no_nest: { color: '', active: false },
  polymer_insulators_contaminated: { color: '', active: false },
  polymer_insulator_good: { color: '', active: false },
  polymer_insulators_flashed: { color: '', active: false },
  polymer_insulators_broken: { color: '', active: false },
  porcelain_insulators_broken: { color: '', active: false },
  porcelain_insulators_contaminated: { color: '', active: false },
  porcelain_insulators_flashed: { color: '', active: false },
  porcelain_insulators_good: { color: '', active: false },
  properly_aligned_insulators: { color: '', active: false },
  wood_pole_cap_problems: { color: '', active: false },
  wood_pole_cavities: { color: '', active: false },
  rust: { color: '', active: false },
  cotter_pin_good: { color: '', active: false },
  vegetation_encroachment: { color: '', active: false },
  pole: { color: '', active: false },
  cross_arm_good: { color: '', active: false },
  cross_arm_damaged: { color: '', active: false },
  joint_rusting: { color: '', active: false },
  joint_rusting_polymer: { color: '', active: false },
  epac_insulator_good: { color: '', active: false },
  epac_insulator_damaged: { color: '', active: false },
  '8080_insulator_good': { color: '', active: false },
  '8080_insulator_damaged': { color: '', active: false },
  double_pole: { color: '', active: false },
};
