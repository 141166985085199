import dateFormat from '@utils/time-formats';

const colorPicker = (type, severity = '') => {
  let color;
  let textColor;

  if (type === 'classes') {
    color = 'primary';
    textColor = '';
  } else if (type === 'severity') {
    switch (severity) {
      case 'None':
        color = 'green darkened-1';
        textColor = 'white';
        break;
      case 'Low':
        color = 'blue lightened-5';
        textColor = 'white';
        break;
      case 'Medium':
        color = 'yellow lightened-1';
        textColor = 'black';
        break;
      case 'High':
        color = 'red darkened-3';
        textColor = 'white';
        break;
      default: break;
    }
  }

  return {
    color,
    textColor,
  };
};

const chipTemplate = (text, color, textColor, size) => `
    <span class="v-chip v-chip--no-color theme--${(textColor === 'white') ? 'dark' : 'light'} v-size--${size} ${color} ${(textColor) || ''}">
      <span class="v-chip__content">${text}</span>
    </span>
`;

const generateChips = (content, type, size) => {
  const colorData = (type === 'classes') ? colorPicker(type) : colorPicker(type, content);
  return chipTemplate(content, colorData.color, colorData.textColor, size);
};

const generateMultiChips = (classes) => classes.reduce((p, c) => p + generateChips(c, 'classes', 'x-small'), '');

export const content = (caption, classes, severity, location, createdAt) => `
  <div id="content">
    <div id="siteNotice"></div>
    <h3 id="firstHeading" class="firstHeading">${caption || 'Not captioned'}</h3>
    <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
    <div id="bodyContent" class="py-2">
      <h4 class="">Classes:</h4>
      ${generateChips(classes, 'classes', 'x-small')}
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Severity:</h4>
      ${generateChips(severity, 'severity', 'x-small')}
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Location:</h4>
      <span class="subtitle">${location}</span>
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Date Processed:</h4>
      <span class="subtitle">${dateFormat(createdAt, 'FULL_DATE')}</span>
    </div>
  </div>
`;

export const dowContent = (caption, severity, location) => `
  <div id="content">
    <div id="siteNotice"></div>
    <h3 id="firstHeading" class="firstHeading">${caption || 'Not captioned'}</h3>
    <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
    <div id="bodyContent" class="py-2">
      <h4 class="mt-2">Severity:</h4>
      ${generateChips(severity, 'severity', 'x-small')}
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Location:</h4>
      <span class="subtitle">${location}</span>
    </div>
  </div>
`;

export const EpriContent = (folder, amount, location) => `
<div id="content">
  <div id="siteNotice"></div>
  <h3 id="firstHeading" class="firstHeading">${(folder !== '') ? folder : 'No folder name'}</h3>
  <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
  <div id="bodyContent" class="py-2">
    <h4 class="">Amount of Images:</h4>
    ${generateChips(amount, 'classes', 'x-small')}
    <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
    <h4 class="mt-2">Location:</h4>
    <span class="subtitle">${location}</span>
  </div>
</div>
`;

export const nfContent = (caption, classes, severity, location, dateInspected) => `
  <div id="content">
    <div id="siteNotice"></div>
    <div id="bodyContent" class="py-2">
      <h4 class="">Classes:</h4>
      ${generateMultiChips(classes)}
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Severity:</h4>
      ${generateChips(severity, 'severity', 'x-small')}
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Location:</h4>
      <span class="subtitle">${location}</span>
      <hr role="separator" aria-orientation="horizontal" class="v-divider theme--light mt-2">
      <h4 class="mt-2">Date Inspected:</h4>
      <span class="subtitle">${dateInspected}</span>
    </div>
  </div>
`;
