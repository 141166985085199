<template>
  <div :id="`donut-chart-${chartId}`" ref="chart"></div>
</template>

<script>
import Plotly from '@components/charts/custom-plotly';

export default {
  name: 'PieChartV2',
  props: {
    chartId: { type: String, required: true },
    labels: { type: Object, default: Object },
    title: { type: String, default: '' },
  },
  data: () => ({
    pieChart: null,
  }),
  methods: {
    async initializeChart() {
      const { chart } = this.$refs;

      const trace = {
        labels: Object.keys(this.labels),
        values: Object.values(this.labels),
        type: 'pie',
        textinfo: 'label+percent+name',
        textposition: 'inside',
        hole: 0.4,
      };

      const data = [trace];
      const layout = {
        title: {
          text: this.title,
          font: { family: 'Courier New, monospace', size: 24 },
          showlegend: true,
          legend: { orientation: 'h', valign: 'bottom' },
        },
      };

      await Plotly.react(chart, data, layout, {
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
      });

      this.pieChart = await Plotly.relayout(chart, { showLegend: true });
    },
  },
  watch: {
    async labels() {
      await this.initializeChart();
    },
  },
  async mounted() {
    await this.initializeChart();
  },
};
</script>
