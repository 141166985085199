<template>
  <v-card>
    <v-card-title>
      <v-container>
        <v-row>
          <v-col cols="6">
            <div class="caption-edit" v-if="captionEditor">
              <v-text-field
                outlined
                label="Caption"
                id="edit-caption-image"
                v-model="imageCaption"
                :error-messages="imageError.caption"
              ></v-text-field>
              <v-card-actions class="text-center">
                <v-btn color="primary" @click="captionEdit">Cancel</v-btn>
                <v-btn color="primary" @click="onSaveCaption">Save</v-btn>
              </v-card-actions>
            </div>
            <div class="caption" v-else>
              <span class="headline">
                {{getCaption(display)}}
              </span>
              <v-btn class="mx-2" icon small @click="captionEdit">
                <v-icon>edit</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-card class="pa-4">
              <div id="image-full">
                <AnnotoriousRenderer v-if="type === 'ai'"/>
                <v-img
                  height="auto"
                  :src="display.url"
                  :key="display.date"
                  v-else
                />
              </div>
            </v-card>
            <v-card-actions class="mt-5" align>
              <v-spacer></v-spacer>
              <v-btn
                fab
                small
                left
                color="primary"
                @click="navigateImage(-1)"
              >
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                fab
                small
                right
                color="primary"
                @click="navigateImage(1)"
              >
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    fab
                    small
                    color="primary"
                    v-on="on"
                    @click="openAnnotator"
                  ><v-icon>brush</v-icon></v-btn>
                </template>
                Annotate
              </v-tooltip>
            </v-card-actions>
            <div class="text-center">
              {{ imageIndex }} of {{ images.length }}
            </div>
          </v-col>
          <v-col cols="6">
            <ImageNotes :image="currentImage" :type="type" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="closeDialog" text>Close</v-btn>
      <FullScreenButton
        buttonType="text"
        :url="display.url"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ImageNotes from '@components/images/ImageNotes.vue';
import AnnotoriousRenderer from '@components/annotations/AnnotoriousRenderer.vue';
import FullScreenButton from '@components/images/FullScreenButton.vue';
import { generateImageViewer } from '@components/annotations/fabric-renderer';

export default {
  name: 'ImageZoomer',
  props: ['images', 'type'],
  components: {
    AnnotoriousRenderer,
    ImageNotes,
    FullScreenButton,
  },
  data: () => ({
    dialog: false,
    annotateDialog: false,
    captionEditor: false,
    imageCaption: '',
    zoomerActive: false,
    isFullScreen: false,
    imgHeight: '500',
    generating: false,
  }),
  methods: {
    ...mapActions(['updateImage', 'setNotification', 'setCurrentImage', 'setImageError']),
    getCaption(image) {
      const { type } = this;
      const { length } = image.process_tracking;

      // If image includes a processedImageUrl, is type 'ai', and a caption exists
      const aiTabCaption = (image.processedImageUrl && type === 'ai')
        && image.process_tracking[length - 1].caption;

      // If the type is original and caption exists as a property
      const originalTabCaption = image.caption && type === 'original';

      if (aiTabCaption) return image.process_tracking[length - 1].caption;
      if (originalTabCaption) return image.caption;
      return 'Add a caption';
    },
    navigateImage(direction) {
      const imgIndex = this.images.findIndex(
        (image) => image.date === this.currentImage.date
        && image.filename === this.currentImage.filename,
      );
      const nextIndex = imgIndex + direction;
      if (imgIndex !== -1 && nextIndex >= 0 && nextIndex < this.images.length) {
        // setImage(this.images[nextIndex].filename);
        this.setCurrentImage(this.images[nextIndex]);
      }
    },
    closeDialog() {
      this.$emit('closeZoomer', false);
      this.setImageError({ notes: '', caption: '' });
      this.captionEditor = false;
    },
    captionEdit() {
      this.captionEditor = !this.captionEditor;
    },
    async onSaveCaption() {
      if (this.imageCaption.length === 0) {
        const error = { ...this.imageError };
        error.caption = 'You have not entered in an image caption to save.';
        this.setImageError(error);
      } else {
        const editingImage = { ...this.currentImage };
        const imageToUpdate = {
          cid: this.currentCompany.cid,
          pid: this.currentProject.pid,
          iid: this.currentImage.id,
          update: {},
        };
        if (this.type === 'original') imageToUpdate.update.caption = this.imageCaption;
        else {
          const { length } = editingImage.process_tracking;
          imageToUpdate.update.process_tracking = editingImage.process_tracking;
          imageToUpdate.update.process_tracking[length - 1].caption = this.imageCaption;
        }
        imageToUpdate.image = { update: imageToUpdate.update };
        // TODO: need to handle update process caption
        const response = await this.updateImage(imageToUpdate);

        if (response.status === 200) {
          const { data } = response;
          this.setCurrentImage(data);

          const { filename } = data;
          const imageType = (this.type === 'original') ? 'image' : 'processed image';
          this.setNotification({ success: true, message: `Caption for the ${imageType} with filename ${filename} has been updated` });
          if (this.imageError.caption) {
            const error = { ...this.imageError };
            error.caption = '';
            this.setImageError(error);
          }
          if (this.captionEditor) this.captionEditor = false;
        }
      }
    },
    openAnnotator() {
      this.$emit('annotate');
    },
    toggleZoomer() {
      this.zoomerActive = !this.zoomerActive;
    },
    toggleFullScreen() {
      const elem = document.getElementById('image-full');
      elem.onfullscreenchange = this.handleFullscreenChange;

      if (!document.fullscreenElement) elem.requestFullscreen();
      else document.exitFullscreen();
    },
    handleFullscreenChange(event) {
      const elem = event.target;
      this.isFullScreen = document.fullscreenElement === elem;
    },
    handleNextPrev(e) {
      switch (e.keyCode) {
        case 37: // left
          this.navigateImage(-1);
          break;
        case 39: // right
          this.navigateImage(1);
          break;
        default:
      }
    },
    async exportAnnotation() {
      this.generating = true;
      const [head, body, script] = await generateImageViewer(this.currentImage);
      const newWin = window.open('', Date.now().toString());
      newWin.document.head.innerHTML = head;
      newWin.document.body.innerHTML = body;
      newWin.document.head.appendChild(script);
      this.generating = false;
    },
  },
  computed: {
    ...mapGetters(['allImages', 'currentImage', 'imageError', 'currentAnnotations', 'currentProject', 'currentCompany']),
    display() {
      const image = { ...this.currentImage };
      if (this.type === 'original') {
        image.url = image.originalImageUrl;
        // delete image.originalImageUrl;
      } else {
        image.url = image.processedImageUrl;
      }

      return image;
    },
    imageIndex() {
      return Number(this.images.findIndex(
        (image) => image.date === this.currentImage.date
        && image.filename === this.currentImage.filename,
      )) + 1;
    },
  },
  mounted() {
    if (this.type === 'ai') {
      const { length } = this.currentImage.process_tracking;
      this.imageCaption = this.currentImage.process_tracking[length - 1].caption;
    } else this.imageCaption = this.currentImage.caption;
  },
  created() {
    window.addEventListener('keydown', this.handleNextPrev);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleNextPrev);
  },
  watch: {
    isFullScreen(v) {
      if (v) this.imgHeight = '100vh';
      else this.imgHeight = '500';
    },
    currentImage: {
      deep: true,
      handler(newImage) {
        this.imageCaption = (this.type === 'original')
          ? newImage.caption
          : newImage.process_tracking[newImage.process_tracking.length - 1].caption;
      },
    },
  },
};
</script>
