<template>
  <v-container fluid>
    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import subscriptionAccess from '../../router/subscription-access';

export default {
  name: 'Analytics',
  props: ['pid'],
  methods: {
    ...mapActions(['getDashboardImages', 'getProjects']),
    async getFirstProject() {
      let res;
      try {
        if (this.currentUser.adminStatus) {
          res = await this.getProjects(`company_id=${this.currentCompany.cid}`);
        } else {
          res = await this.getProjects(`user_id=${this.currentUser.uid}`);
        }
        const projects = res.data;
        const [project] = projects;

        return project;
      } catch (err) {
        return err;
      }
    },
    async fetchProjects() {
      let res;
      try {
        if (this.currentUser.adminStatus) {
          res = await this.getProjects(`company_id=${this.currentCompany.cid}`);
        } else {
          res = await this.getProjects(`user_id=${this.currentUser.uid}`);
        }
        const projects = res.data;
        return projects;
      } catch (err) {
        return new Error(err);
      }
    },
  },
  computed: {
    ...mapGetters(['currentCompany', 'currentProject', 'currentUser']),
  },
  async created() {
    // Check dashboard access
    const canAccess = await subscriptionAccess({
      customerId: this.currentCompany.customerId,
      product: 'dashboard',
    });

    const projects = await this.fetchProjects();
    const [firstProject] = projects;
    const { pid } = this.$route.params;

    const currentPid = (!pid) ? firstProject.pid : pid;

    // Can't access
    if (!canAccess) this.$router.push('/analytics/no-access');
    else this.$router.push(`/analytics/insights/${currentPid}`);
  },
};
</script>
