<template>
  <v-container fluid>
    <div class="map-container">
      <v-card class="map pa-2" ref="map">
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Map from '@utils/map';
import retry from 'async-retry';
import gmapsInit from '../../../gmaps_api';

export default {
  name: 'GeneralMap',
  props: ['images', 'folderImages'],
  data() {
    return {
      googleMap: null,
    };
  },
  methods: {
    ...mapActions(['setSeverityFilterClicked', 'setCurrentImage', 'setIsFormOriginalTab', 'setLazyLoadPointer']),
    // Primarily used to check if the image list is the same or if
    // any of the image severities have changed
    isEqual(list1, list2) {
      if (list1.length !== list2.length) return false;
      let i = 0;

      while (i < list1.length) {
        if (list1[i] !== list2[i]) return false;
        i += 1;
      }

      return true;
    },
    async getDefaultLocation() {
      return this.googleMap.getCoordinatesFromAddress(this.currentCompany.address);
    },
    showAllMarkers() {
      const bounds = this.getBoundsToFitAllMarkers();
      this.googleMap.map.fitBounds(bounds);
      this.googleMap.map.setCenter(bounds.getCenter());
    },
    getBoundsToFitAllMarkers() {
      const bounds = new this.googleMap.google.maps.LatLngBounds();
      // eslint-disable-next-line max-len
      const locations = this.images.filter((image) => image.location && image.location.length === 2);
      locations.forEach(({ location }) => {
        bounds.extend(new this.googleMap.google.maps.LatLng(location[0], location[1]));
      });

      return bounds;
    },
    // Determines if there is already an image retained within the session storage
    // and if there is we would have to refocus the map to the previous clicked point before
    // the user refreshed the page
    async focusImageOnMap(image) {
      let lat;
      let lng;
      // if is from original tab, show all markers
      if (this.images.length > 0 && (this.isFromOriginalTab || this.severityFilterClicked)) {
        this.showAllMarkers();
        this.setIsFormOriginalTab(false);
        this.setSeverityFilterClicked(false);
        return;
      }
      // const currentFilename = this.googleMap.getImageSession();
      const currentFilename = image.filename;

      if (currentFilename) {
        [lat, lng] = image.location;

        if (!lat || !lng) {
          const defaultLocation = await this.getDefaultLocation();
          const { location } = defaultLocation.geometry;
          lat = location.lat();
          lng = location.lng();
        }

        const pos = new this.googleMap.google.maps.LatLng(lat, lng);

        const marker = this.googleMap.markers.find((m) => {
          const lats = m.getPosition().lat() === pos.lat();
          const lngs = m.getPosition().lng() === pos.lng();

          return lats && lngs;
        });

        this.googleMap.map.panTo(pos);
        this.googleMap.google.maps.event.trigger(marker, 'spider_click');
        const pointer = this.folderImages.findIndex((img) => img.filename === currentFilename);
        if (pointer > -1) {
          this.setLazyLoadPointer(pointer);
        }
        this.googleMap.map.setZoom(19);
      }
    },
    async initMap() {
      try {
        const googleApi = await gmapsInit();
        const mapElement = this.$children[0].$el;

        // If there are no images with location, then it'll default
        // the zoom value to 4, and center the map to the center of the
        // US
        const zoom = (this.images.length > 0) ? 13 : 4;
        const center = (this.images.length > 0)
          ? { lat: this.images[0].location[0], lng: this.images[0].location[1] }
          : { lat: 38.64398880555556, lng: -90.53346227777777 };

        this.googleMap = new Map(
          googleApi,
          mapElement,
          { zoom, center },
          parseInt(this.$route.params.pid, 10),
        );

        this.googleMap.setMapId(this.companyHas('roadmap'));

        if (this.images.length > 0) {
          const centerControlDiv = document.createElement('div');
          this.googleMap.setCenterControl(centerControlDiv, 'Toggle Heatmap');
          const markerController = document.createElement('div');
          this.googleMap.setCenterControl(markerController, 'Toggle Markers', this.toggleMarkers);
        }

        // With no location data, it'll find the address of the client's headquarters
        // and set the map bounds to that location
        if (this.images.length === 0) {
          await retry(
            async (bail) => {
              try {
                const defaultLocation = await this.getDefaultLocation();
                const { location } = defaultLocation.geometry;
                const lat = location.lat();
                const lng = location.lng();
                let { bounds } = defaultLocation.geometry;
                const coordinates = new this.googleMap.google.maps.LatLng(lat, lng);
                if (!bounds) {
                  bounds = new this.googleMap.google.maps.LatLngBounds();
                }
                bounds.extend(coordinates);
                this.googleMap.map.fitBounds(bounds);
              } catch (error) {
                bail(error);
              }
            },
            {
              retries: 5,
            },
          );
        }

        this.googleMap.clearMarkers();

        // Sets up overlapping spiderfier
        this.googleMap.setOverlappingSpiderfier();
        // Sets map markers
        const markers = this.googleMap.setMarkers(this.images);

        // Sets heatmap
        this.googleMap.setHeatMap(this.images);

        // Sets marker clusterer
        this.googleMap.setMarkerClusterer(markers);
        this.googleMap.setMapLoadedEvent();
        if (!this.severityFilterClicked) {
          await this.focusImageOnMap();
        } else if (this.images.length > 0) {
          this.showAllMarkers();
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      return this.googleMap;
    },
  },
  computed: {
    ...mapGetters(['severityFilterClicked', 'companyHas', 'isFromOriginalTab', 'currentFolder', 'currentImage', 'allImages', 'currentCompany']),

    // Determins whehther or not the image set has location data. Used within the
    // currentImage watcher to keep the map from breaking when it tries to refocus
    // on a point that doesn't exist
    imageSetHasLocation() {
      return this.images.length > 0;
    },
  },
  watch: {
    currentImage(newImage, oldImage) {
      if (newImage.source === 'hitl') return;

      if (newImage.filename === oldImage.filename) return;

      // If the image set has no location, do not re-focus the map at all
      if (!this.imageSetHasLocation) return;

      // Resets the values in session storage to allow users back onto the
      // point on refresh
      this.googleMap.setImageSession(newImage.filename);

      // Refocuses back to the original point that was clicked
      this.focusImageOnMap(newImage);
    },
    images(newImages, oldImages) {
      // Retrieve filenames of the new image list and old image list
      const newFilenames = newImages.map((image) => image.filename);
      const oldFilenames = oldImages.map((image) => image.filename);

      // Retrieve severity levels of both old and new list of images
      const newSeverities = newImages
        .filter((image) => image.processedImageUrl)
        .map((image) => image.process_tracking.slice(-1)[0].severity);
      const oldSeverities = oldImages
        .filter((image) => image.processedImageUrl)
        .map((image) => image.process_tracking.slice(-1)[0].severity);

      // Check if images and severity levels match
      const imagesMatch = this.isEqual(newFilenames, oldFilenames);
      const severitiesMatch = this.isEqual(newSeverities, oldSeverities);

      // If either of them do not match, we change the color of the coordinate point
      if (!imagesMatch || !severitiesMatch) {
        this.googleMap.changeColor(this.currentImage);
      }
    },
  },
  async created() {
    await this.initMap();
  },
  destroyed() {
    // If there's a googleMap Instance initialized,
    // Remove any marker listeners and info window listeners
    if (this.googleMap) {
      this.googleMap.removeImageSession();

      if (this.googleMap.markerListeners.length > 0) {
        this.googleMap.removeMarkerListeners();
      }

      if (this.googleMap.infoWindowListeners.length > 0) {
        this.googleMap.removeInfoWindowListeners();
      }
    }
  },
};
</script>

<style scoped>
  .map-container {
    padding: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: #ccc #ccc #999 #ccc;
    -webkit-box-shadow: rgba(64, 64, 64, 0.5) 0 2px 5px;
    -moz-box-shadow: rgba(64, 64, 64, 0.5) 0 2px 5px;
    box-shadow: rgba(64, 64, 64, 0.5) 0 2px 5px;
    width: 100%;
  }
  .map {
    width: 100%;
    height: 50vh;
  }
  .item {
    margin-left: 20px;
  }
  .custom-clustericon {
    background: var(--cluster-color);
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
  }

  .custom-clustericon::before,
  .custom-clustericon::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;

    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: var(--cluster-color);
    opacity: 0.2;
    border-radius: 100%;
  }

  .custom-clustericon::before {
    padding: 7px;
  }

  .custom-clustericon::after {
    padding: 14px;
  }
  .custom-clustericon-0 {
    --cluster-color: #000000;
  }
  .custom-clustericon-1 {
    --cluster-color: #66BB6A;
  }

  .custom-clustericon-2 {
    --cluster-color: #00B0FF;
  }

  .custom-clustericon-3 {
    --cluster-color: #FFEE58;
  }

  .custom-clustericon-4 {
    --cluster-color: #F44336;
  }
</style>
