<template>
  <v-container fluid id="image-classes">
    <Chip
      v-for="(classType, index) in labels"
      :key="`${index}-${classType}`"
      :color="getChipColor(classType)"
      size="x-small"
      :text="getLabelText(classType, classes)"
      @clicked="(status) => filterAnnotes(classType, status)"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import find from 'lodash/find';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import Chip from '@components/common/Chip.vue';
import labelFilterStatus from '@utils/labelFiltersStatus';
import { severityColorPicker } from '@methods/helpers';

export default {
  name: 'ImageClasses',
  props: ['classes'],
  data: () => ({
    labelStatus: {},
    labels: [],
  }),
  components: {
    Chip,
  },
  methods: {
    ...mapActions(['removeLabelFilter', 'addLabelFilter']),
    uniqueClassTypes: (classes) => uniq(classes),
    getChipColor(classType) {
      // Handles the classTypes with <severity>:<label> format
      let key;
      if (classType.includes(':')) {
        key = classType.split(':')[1].toLowerCase().split(' ').join('_');
      } else key = classType.toLowerCase().split(' ').join('_');

      if (labelFilterStatus[key]) return labelFilterStatus[key].color;
      return 'grey';
    },
    getLabelText(classType, labels) {
      const labelsOnly = labels.map((label) => ((typeof label === 'string') ? label.split(':').slice(-1)[0].trim() : label.label));
      // Removes the : from labels shown as <severity>:<label> format
      const classLabel = (classType.includes(':')) ? classType.split(':')[1] : classType;
      return `${classLabel} (${labelsOnly.filter((label) => label === classType).length})`;
    },
    filterAnnotes(classType, status) {
      const classOnly = classType.split(':').slice(-1)[0].trim();
      if (status) this.addLabelFilter(classOnly);
      else this.removeLabelFilter(classOnly);

      const formattedLabel = classOnly.toLowerCase().split(' ').join('_');

      if (this.labelStatus[formattedLabel]) {
        this.labelStatus[formattedLabel].active = !this.labelStatus[formattedLabel].active;

        if (this.labelStatus[formattedLabel].active) {
          const { severity } = find(
            this.flatProjectLabels,
            (abbreviation) => abbreviation.faultType === classOnly,
          );

          this.labelStatus[formattedLabel].color = severityColorPicker(severity.toLowerCase());
        } else this.labelStatus[formattedLabel].color = '';
      }
    },
  },
  computed: {
    ...mapGetters(['flatProjectLabels']),
  },
  watch: {
    classes(newClasses) {
      this.labels = uniqBy(newClasses, 'label').map((label) => {
        if (typeof label === 'string') {
          return label.split(':').slice(-1)[0].trim();
        }
        return label.label;
      });
    },
  },
  mounted() {
    this.labelStatus = labelFilterStatus;
    this.labels = uniqBy(this.classes, 'label').map((label) => ((typeof label === 'string') ? label.split(':').slice(-1)[0].trim() : label.label));
  },
};
</script>
