<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" sm="12" md="12" lg="6" xl="6">
        <DonutChart
          chartId="severity"
          :key="`severity-${severityValuesSum}`"
          title="Severity"
          :labels="severityCount['labels']"
          :values="severityCount['values']"
          :showLegend="true"
          orientation="h"
          v-if="!companyHas('poleByFolders')"
        />

        <DonutChart
          chartId="priority"
          :key="`priority-${priorityValuesSum}`"
          title="Priority"
          :labels="priorityCount['labels']"
          :values="priorityCount['values']"
          :showLegend="true"
          orientation="h"
          v-else-if="priorityValuesSum > 0"
        />
      <div v-else>
        <span class="priority-chart-title">Priority Chart</span>
        <span class="no-priority">{{ priorityMessage }}</span>
        </div>
      </v-col>
      <v-col xs="12" sm="12" md="12" lg="6" xl="6">
        <DonutChart
          :key="`fault-${Object.keys(faultCount).length}`"
          chartId="fault"
          title="Fault Detection"
          :labels="Object.keys(faultCount)"
          :values="Object.values(faultCount).map((images) => images.length)"
          :showLegend="true"
          orientation="h"
          v-if="!companyHas('poleByFolders')"
        />
        <DonutChart
          :key="`priority-${faultValuesSumInsights}`"
          chartId="fault"
          title="Fault Detection"
          :labels="faultCountInsights['labels']"
          :values="faultCountInsights['values']"
          :showLegend="true"
          orientation="h"
          v-else
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DonutChart from '@components/charts/DonutChart.vue';
import { mapGetters } from 'vuex';
import {
  all,
} from '../filters/faults';

export default {
  name: 'FaultSummaryCharts',
  props: ['images'],
  data: () => ({
    priorityMessage: 'No structure has been assigned a priority. Please assign a priority to a pole see the priority chart',
  }),
  components: {
    DonutChart,
  },
  methods: {
    countDetectedFaults(images) {
      const count = {};

      images
        .forEach((image) => {
          const { length } = image.process_tracking;
          const { labels } = image.process_tracking[length - 1];
          labels.forEach((classType) => {
            if (!(classType in count)) {
              count[classType] = [];
            }
            count[classType].push(image);
          });
        });

      return count;
    },
    imagesToUse() {
      // this function adds a null array for images without locations
      return this.images.map((image) => {
        const img = { ...image };
        if (image.location === null || image.location === undefined) img.location = [null, null];
        return img;
      });
    },
  },
  computed: {
    ...mapGetters(['currentFilterStatus', 'allFolders', 'companyHas', 'companyFolders', 'allImages', 'processedImagesR']),
    severityCount() {
      const severityLabels = ['None', 'Low', 'Medium', 'High'];
      const severityValues = [0, 0, 0, 0];

      this.imagesToUse().forEach((image) => {
        const { length } = image.process_tracking;
        const { severity } = image.process_tracking[length - 1];
        if (severity !== undefined) {
          const key = severityLabels.indexOf(severity);
          severityValues[key] += 1;
        }
      });
      return {
        labels: severityLabels,
        values: severityValues,
      };
    },
    priorityCount() {
      const priorityLabels = ['None', 'TD-1', 'TD-2', 'TD-4', 'TD-5', 'Emergency'];
      const priorityValues = [0, 0, 0, 0, 0, 0];

      this.allFolders.forEach((folder) => {
        const priority = folder.overall_severity;
        const key = priorityLabels.indexOf(priority);
        priorityValues[key] += 1;
      });
      return {
        labels: priorityLabels,
        values: priorityValues,
      };
    },
    faultCount() {
      return this.countDetectedFaults(this.imagesToUse());
    },
    faultCountInsights() {
      // return this.countDetectedFaults(this.images);
      const faultLabels = all;
      const faultValues = [];
      all.forEach(() => {
        faultValues.push(0);
      });

      this.companyFolders.forEach((folder) => {
        const folderFaults = folder.faults;
        folderFaults.forEach((fault) => {
          const key = faultLabels.indexOf(fault);
          faultValues[key] += 1;
        });
      });
      return {
        labels: faultLabels,
        values: faultValues,
      };
    },
    severityValuesSum() {
      return this.severityCount.values.reduce((a, b) => a + b, 0);
    },
    priorityValuesSum() {
      return this.priorityCount.values.reduce((a, b) => a + b, 0);
    },
    faultValuesSumInsights() {
      return this.faultCountInsights.values.reduce((a, b) => a + b, 0);
    },
  },
};
</script>

<style scoped>
.priority-chart-title {
    font-family: "Courier New", monospace;
    font-size: 24px;
    fill: rgb(68, 68, 68);
    opacity: 1;
    font-weight: normal;
    white-space: pre;
    width: 100%;
    text-align: center;
    display: block;
    margin-top: 4%;
    margin-bottom: 10%;
}

.no-priority {
    display: block;
    width: 80%;
    margin: 0 auto;
    text-align: center;
}
</style>
