<template>
  <v-container>
    <v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="2">
            <v-btn icon text large @click="goBack" id="back-btn">
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="10">
            <v-text-field
              id="image-list-search"
              outlined
              v-model="search"
              append-icon="mdi-magnify"
              label="Search Images"
              single-line
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>
    <v-data-table
      id="images-list-table"
      :headers="headers"
      :items="formatImages(allImages)"
      class="elevation-1"
      :search="search"
      v-scroll:#images-list-table="onScroll"
      :loading="loadingCurrentImage"
    >
      <template v-slot:item="{ item, index }">
        <tr
          :id="`image-${index}`"
          @click="setImage(item)"
          :class="(isSelected(item)) ? 'selected-image' : 'not-selected-image'"
        >
          <td>
            <v-btn icon text>
              <v-icon dark>mdi-image</v-icon>
            </v-btn>
          </td>
          <td>
            <span>{{ item.filename }}</span>
          </td>
          <td>
            <span>{{ item.caption }}</span>
          </td>
          <td>
            <span>{{ item.date }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dateFormat from '@utils/time-formats';

export default {
  name: 'ImageSelector',
  data: () => ({
    search: '',
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: '',
      },
      {
        text: 'File Name',
        align: 'start',
        sortable: false,
        value: 'filename',
      },
      { text: 'Caption', value: 'caption' },
      { text: 'Date Uploaded', value: 'date' },
    ],
    loadingCurrentImage: false,
  }),
  methods: {
    ...mapActions(['setShowFolders', 'setCurrentImage']),
    goBack() {
      this.setShowFolders(true);
    },
    formatImages(images) {
      if (images.length < 1) return [];

      const newImages = images.map((image) => {
        const current = { ...image };

        current.date = dateFormat(current.date, 'DAY_DATE_TIME');
        return current;
      });

      return newImages;
    },
    isSelected(image) {
      return image.filename === this.currentImage.filename;
    },
    setImage(item) {
      this.loadingCurrentImage = true;
      const [image] = this.allImages.filter((img) => img.filename === item.filename);
      this.setCurrentImage(image);
      this.loadingCurrentImage = false;
    },
    onScroll(e) {
      // debounce if scrolling fast
      // eslint-disable-next-line no-unused-expressions
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start = rows + this.perPage > this.allImages.length
          ? this.allImages.length - this.perPage : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },
  computed: {
    ...mapGetters(['allImages', 'currentImage']),
  },
};
</script>

<style scoped>
#images-list-table{
  max-height: 250px;
  overflow: auto;
}
.selected-image {
  cursor: pointer;
  background-color: #E3F2FD;
}
.not-selected-image {
  cursor: pointer;
}
</style>
