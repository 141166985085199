<template>
  <v-card>
    <v-container fluid>
      <v-row>
        <v-col v-for="(image, k) in imageSlice" :key="k" class="d-flex child-flex" cols="6" sm="4">
          <v-card
            width="200"
            hover
            :ripple="true"
            @click="emitTransition(k)"
            flat
            position="relative">
          <v-img
            :src="image.url"
            :lazy-src="image.url"
            aspect-ratio="1"
            class="align-end"
            id="img"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-checkbox
            :value="image.id"
            v-model="selected"
            :key="`image-${image.id}`"
            class="float-right p-bottom-right img-checkbox"
            on-icon="mdi-checkbox-marked"
            off-icon="mdi-checkbox-blank"
            @click.stop="onImageToggled"
            :disabled="processingImage"
            :id="`checkbox-${k}`"
            dark
            multiple
          ></v-checkbox>
          <div class="float-right p-top-right" v-if="showAnnotatedIcon(image)">
            <v-icon color="blue">check_circle</v-icon>
          </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center">
      <v-pagination
        v-model="pageN"
        :length="numberPages"
        total-visible="5"
        @next="emitTransition"
        @prev="emitTransition"
        @input="emitTransition(undefined)"
        :disabled="processingImage"
      ></v-pagination>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ImagePicker',
  props: ['images'],
  data() {
    return {
      pageN: 1,
      selected: this.$store.state.images.selected,
    };
  },
  methods: {
    ...mapActions(['setCurrentImage', 'setSelectedImages', 'setLazyLoadPointer']),
    onImageToggled() {
      const images = this.images.filter((image) => this.selected.includes(image.id));
      this.setSelectedImages(images);
    },
    setPointer(image) {
      const pointer = this.images.findIndex((img) => img.filename === image.filename);
      if (pointer > -1) {
        this.setLazyLoadPointer(pointer);
      }
      return pointer;
    },
    emitTransition(k) {
      const s = (this.pageN - 1) * this.lazyLoadVisible;
      let img;

      if (k) img = this.images[s + k];
      else img = this.images[s];

      this.setPointer(img);
    },
    showAnnotatedIcon: (image) => {
      if (!image.processedImageUrl) return false;

      const { length } = image.process_tracking;
      const { labels } = image.process_tracking[length - 1];

      // Since annotated images do have labels as <severity>:<label>
      // i.e None: Conductor Good, we use the color to dictate whether or not
      // the image has been annotated.

      if (!labels) return false;

      // So we go through the labels array and try to find a label that includes a colon
      // const isAnnotatedIndex = labels.findIndex((label) => label.includes(':'));

      const isAnnotatedIndex = labels.findIndex((label) => (typeof label === 'string' ? label.includes(':') : !label.from_model));
      // If the index is -1, which means couldn't be found, then return false
      if (isAnnotatedIndex === -1) return false;

      // Otherwise modified annotations have been found and we can display the icon
      return true;
    },
  },
  computed: {
    ...mapGetters(['allImages', 'processingImage', 'lazyLoadVisible', 'loadingImages', 'currentProject', 'lazyLoadPointer', 'currentImage']),
    numberPages() {
      const { length } = this.images;
      const isDivisble = length % this.lazyLoadVisible === 0;
      return (isDivisble) ? length / this.lazyLoadVisible
        : Math.ceil(length / this.lazyLoadVisible);
    },
    imageSlice() {
      const s = (this.pageN - 1) * this.lazyLoadVisible;
      const p = s + this.lazyLoadVisible;
      const slice = this.images.slice(s, p);

      return slice;
    },
  },
  watch: {
    '$store.state.images.selected': function (sel) { // eslint-disable-line func-names
      this.selected = sel.map((img) => img.id);
    },
    lazyLoadPointer(p) {
      this.$nextTick(() => {
        this.pageN = Math.ceil((p + 1) / this.lazyLoadVisible);
      });
    },
    pageN() {
      // 5 -> 3
      this.$nextTick(() => {
        this.pageN = this.pageN || 1;
      });
    },
    currentImage(newCurrentImage) {
      this.setPointer(newCurrentImage);
    },
  },
};
</script>

<style scoped>
  .scrollbar {
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
  }
  .scrollbar::-webkit-scrollbar:horizontal{
    height: 13px;
  }
  .scrollbar::-webkit-scrollbar {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
  }
  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, .5);
  }
  .p-bottom-right{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 30px;
  }
  .p-top-right{
    position: absolute;
    top: 0;
    right: 0;
    height: 30px;
  }
</style>
