/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { cloneDeep } from 'lodash';
import LongPoll from '@utils/longpoll';
import { baseProcessUrl } from '@utils/endpoint';
import services from '@services';
import uniq from 'lodash/uniq';

const state = {
  tasks: [],
  task: [],
  loadingTasks: false,
  loadingTask: false,
  progress: {},
};

/* eslint no-shadow: ["error", { "allow": ["state"] }] */
const getters = {
  allInProgressTasks: (state) => {
    const taskIds = Object.keys(state.tasks);
    const taskArray = [];

    taskIds.forEach((taskId) => {
      const taskObject = state.tasks[taskId];
      taskObject.taskId = taskId;
      taskArray.push(taskObject);
    });

    return taskArray;
  },
  currentProgress: (state) => state.progress,
  loadingTasks: (state) => state.loadingTasks,
  loadingTask: (state) => state.loadingTask,
};

const actions = {
  getTasks({ commit }, uid) {
    commit('loading_tasks', true);

    return new Promise((resolve, reject) => {
      axios.get(`${baseProcessUrl}/batch_process/tasks/${uid}`)
        .then((res) => {
          commit('loading_tasks', false);

          if (res.status === 200) {
            commit('fetch_tasks', res.data);
            resolve(res.data);
          } else resolve([]);
        })
        .catch((err) => reject(err));
    });
  },
  getTask({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      axios.get(`${baseProcessUrl}/batch_process/result/${taskId}`)
        .then((res) => {
          commit('fetch_task', res);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  },
  updateTaskProgress({ commit }, progress) {
    if (progress.status === 'Success') commit('update_images', progress.result);

    commit('set_processing_progress', progress);
  },
  longPollProgress(store, request) {
    const req = LongPoll();

    req.start(request.type, request.url, request.headers);

    // Sets processing state to true
    store.commit('set_processing', true);

    req.on('finished', (code, results) => {
      // Helper function that turns a string number into a float number
      const getProgress = (progress) => parseFloat(
        progress.slice(0, progress.length - 1),
      ).toFixed(2);
      const progress = cloneDeep(results[request.taskId]);

      // Complete status code is 200
      if (code === 200) {
        // Set the percentage to 100 since the backend doesn't do it automatically
        progress.percentage = 100.00;

        // Run the same actions to mark the process complete
        if (progress.status === 'Success') {
          services.images.get_batch(
            store.getters.currentCompany.cid,
            store.getters.currentProject.pid,
            { images: uniq(progress.result.map((image) => image.id)) },
          )
            .then((res) => {
              // Re-computes the amount of images/processed images
              // within each structures after these images have been processed
              services.folders.recount(
                store.getters.currentCompany.firestore_cid,
                store.getters.currentProject.firestore_pid,
              );
              store.commit('update_images', res.data);
            });
        }
        store.commit('set_processing_progress', progress);

        // Sets the processing state to false (done)
        store.commit('set_processing', false);

        // Set tasks/task to it's initial state when it's done
        store.commit('fetch_tasks', []);
        store.commit('fetch_task', {});

        // Sets a notification
        if (progress.status === 'Success') {
          // If progress status is a success, then set notification message
          // progress.results comes back in array, so take the length of that
          // to get number of images processed
          const finishedNotification = { success: true, message: `Successfully processed ${progress.result.length} image(s)` };
          store.commit('set_notification', finishedNotification);

          // Wait 3 seconds before resetting notification status
          setTimeout(() => {
            store.commit('set_notification', { success: false, message: '' });
          });
        }

        req.abort();
      }

      // Pending status code is 202
      if (code === 202) {
        // Update the percentage with helper function `getProcess`
        progress.percentage = getProgress(results[request.taskId].progress);

        // Update the task progress within Vuex
        if (progress.status === 'Success') store.commit('update_images', progress.results);
        store.commit('set_processing_progress', progress);

        // Retry the request
        req.retry();
      }
    });
  },
};

const mutations = {
  loading_tasks: (state, loading) => { state.loadingTasks = loading; },
  loading_task: (state, loading) => { state.loadingTask = loading; },
  set_processing_progress: (state, progress) => { state.progress = progress; },
  fetch_tasks: (state, tasks) => { state.tasks = tasks; },
  fetch_task: (state, task) => { state.task = task; },
  update_task: (state, task) => { state.task = task; },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
