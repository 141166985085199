<template>
  <v-container fluild>
    <Loading v-if="loadingUsers" />
    <TabViews v-else :vertical="false" :centered="false" :tabs="companyTabs" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Loading from '@components/common/Loading.vue';
import TabViews from '@components/common/TabViews.vue';

export default {
  name: 'Company',
  components: {
    Loading,
    TabViews,
  },
  props: ['cid'],
  data() {
    return {
      companyTabs: [
        {
          tabId: 'employees',
          title: 'Employees',
          icon: 'perm_contact_calendar',
          route: `/company/${this.cid}/employees`,
        },
        {
          tabId: 'teams',
          title: 'Teams',
          icon: 'group',
          route: `/company/${this.cid}/teams`,
        },
      ],
    };
  },
  methods: {
    ...mapActions(['getUsers']),
  },
  computed: {
    ...mapGetters(['currentUser', 'currentCompany', 'loadingUsers']),
  },
  created() {
    this.getUsers(`company_id=${this.currentCompany.cid}`);
  },
};
</script>
