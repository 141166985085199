<template>
  <v-container fluid>
    <v-card class="map pa-2" ref="map">
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { content, dowContent } from '@utils/map/Content';
import { getImage, setImage, removeImage } from '@utils/map/session-storage';
import severityColorPicker from '@components/images/severity';
import gmapsInit from '../../../gmaps_api';

export default {
  name: 'Map',
  props: ['images'],
  data: () => ({
    map: null,
    google: null,
    heatmap: null,
    heatmapOn: false,
    markersOn: false,
    severityCode: {},
    renderingMap: false,
    markers: [],
    currentInfo: {},
    currentMarker: {},
    markerClicked: false,
    markerListeners: [],
    current: '',
    isFilteringByFault: false,
    imagesFilteredByFault: [],
  }),
  created() {
    this.$eventBus.$on('map:updateImage', this.updateImage);
    this.$eventBus.$on('map:filterSeverity', this.filterSeverity);
  },
  methods: {
    ...mapActions(['setCurrentImage', 'setCurrentFolder']),
    isEqual(list1, list2) {
      if (list1.length !== list2.length) return false;
      let i = 0;

      while (i < list1.length) {
        if (list1[i] !== list2[i]) return false;
        i += 1;
      }

      return true;
    },
    centerControl(controlDiv, label, toggleMethod) {
      const controlUI = document.createElement('div');
      controlUI.style.backgroundColor = '#fff';
      controlUI.style.border = '2px solid #fff';
      controlUI.style.borderRadius = '3px';
      controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
      controlUI.style.cursor = 'pointer';
      controlUI.style.marginBottom = '22px';
      controlUI.style.textAlign = 'center';
      controlUI.title = label;
      controlDiv.appendChild(controlUI);

      const controlText = document.createElement('div');
      controlText.style.color = 'rgba(25, 25, 25)';
      controlText.style.fontFamily = 'Roboto, Arial, sans-serif';
      controlText.style.fontSize = '10px';
      controlText.style.lineHeight = '38px';
      controlText.style.paddingLeft = '5px';
      controlText.style.paddingRight = '5px';
      controlText.innerHTML = label;
      controlUI.appendChild(controlText);

      controlUI.addEventListener('click', () => {
        toggleMethod();
      });
    },
    severityMarker(severity) {
      return {
        path:
      'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
        fillColor: severityColorPicker(severity, this.companyHas('td_scale')),
        fillOpacity: 1,
        strokeWeight: 1,
        rotation: 0,
        scale: 1.3,
        anchor: new this.google.maps.Point(15, 30),
      };
    },
    toggleHeatMap() {
      if (this.heatmapOn) this.heatmap.setMap(this.map);
      else this.heatmap.setMap(null);
      this.heatmapOn = !this.heatmapOn;
    },
    toggleMarkers() {
      if (this.markersOn) this.clearMarkers();
      else this.showMarkers();
      this.markersOn = !this.markersOn;
    },
    showMarkers() {
      this.setMapOnAll(this.map);
    },
    clearMarkers() {
      this.setMapOnAll(null);
    },
    clearHeatmap() {
      if (this.heatmap) this.heatmap.setMap(null);
    },
    setMapOnAll(map) {
      this.markers.forEach((marker) => { marker.setMap(map); });
    },
    moveToImage(filename) {
      const [image] = this.images
        .filter((img) => img.filename === filename);
      this.setCurrentImage(image);
    },
    openInfoWindow(currentWindow, marker) {
      this.markerclicked = true;
      currentWindow.open(this.map, marker);
    },
    closeInfoWindow(previousWindow) {
      this.markerClicked = false;
      previousWindow.close();
    },
    setHeatMap(mapDetails) {
      const { maps } = this.google;

      const heatmapData = mapDetails.map((image) => {
        const [lat, lng] = image.location;
        return {
          location: new maps.LatLng(lat, lng),
          weight: this.severityCode[image.severity],
        };
      });

      this.heatmap = new maps.visualization.HeatmapLayer({ data: heatmapData });

      this.heatmap.setMap(this.map);
      this.heatmap.set('radius', 40.0);
      this.heatmapOn = true;
    },
    setMarkers(mapDetails) {
      const { maps } = this.google;
      const bounds = new maps.LatLngBounds();

      this.clearMarkers();
      this.clearHeatmap();

      let longSum = 0;
      let latSum = 0;

      mapDetails.forEach((detail, n) => {
        const {
          caption,
          classes,
          location,
          createdAt,
          severity,
          metadataId,
        } = detail;
        const [lat, long] = location;
        latSum += lat;
        longSum += long;

        const latitude = latSum / (n + 1);
        const longitude = longSum / (n + 1);

        const coordinates = new maps.LatLng(latitude, longitude);
        bounds.extend(coordinates);

        const marker = new maps.Marker({
          position: { lat, lng: long },
          map: this.map,
          icon: this.severityMarker(severity),
        });

        this.markers.push(marker);
        const infoWindow = new maps.InfoWindow({
          position: { lat: latitude, lng: longitude },
          maxWidth: 200,
        });

        const listener = marker.addListener('click', () => {
          this.current = metadataId;
          this.markerClicked = true;
          this.map.panTo(coordinates);

          const zoom = this.map.getZoom();
          if (zoom !== 16) this.map.setZoom(16);

          setImage(metadataId);
          const geocoder = new maps.Geocoder();
          geocoder.geocode({ location: coordinates }, (results, status) => {
            if (status !== 'OK' || !results[0]) {
              throw new Error(status);
            }
            const windowContent = (this.companyHas('dow_info_window'))
              ? dowContent(caption, severity, results[0].formatted_address)
              : content(
                caption,
                classes.join(', '),
                severity,
                results[0].formatted_address,
                createdAt,
              );
            this.moveToImage(metadataId);
            infoWindow.setContent(windowContent);

            infoWindow.addListener('closeclick', () => {
              removeImage();
            });
          });

          if (Object.keys(this.currentInfo).length > 0) this.closeInfoWindow(this.currentInfo);

          this.openInfoWindow(infoWindow, marker);
          this.currentInfo = infoWindow;
          this.currentMarker = marker;
        });
        this.markerListeners.push(listener);

        this.map.fitBounds(bounds);
      });
    },
    async initMap() {
      try {
        this.google = await gmapsInit();
        if (this.google) {
          const { maps } = this.google;
          this.map = new maps.Map(this.$children[0].$el);
          const mapId = (this.companyHas('roadmap')) ? 'roadmap' : 'satellite';

          this.map.setMapTypeId(mapId);
          const bounds = new maps.LatLngBounds();
          this.severityCode = {
            None: 0,
            Low: 10.0,
            Medium: 50.0,
            High: 100.0,
          };

          if (this.mapDetails.length > 0) {
            const centerControlDiv = document.createElement('div');
            this.centerControl(centerControlDiv, 'Toggle Heatmap', this.toggleHeatMap);
            const markerControl = document.createElement('div');
            this.centerControl(markerControl, 'Toggle Markers', this.toggleMarkers);
            this.map.controls[maps.ControlPosition.BOTTOM_CENTER].push(centerControlDiv);
            this.map.controls[maps.ControlPosition.BOTTOM_CENTER].push(markerControl);
          }

          if (this.mapDetails.length === 0) {
            const defaultCluster = [
              [38.64398880555556, -90.53346227777777],
              [38.956436958333335, -89.53787868055555],
              [38.97904263888889, -90.43658578703703],
              [39.37555106944444, -90.31008775000001],
            ];

            defaultCluster.forEach((defaultLoc) => {
              const coordinates = new maps.LatLng(...defaultLoc);
              bounds.extend(coordinates);
              const marker = new maps.Marker({
                position: { lat: defaultLoc[0], lng: defaultLoc[1] },
                map: this.map,
              });
              this.markers.push(marker);
              this.map.fitBounds(bounds);
            });
            this.clearMarkers();
          }
        }
        this.setMarkers(this.mapDetails);
        this.setHeatMap(this.mapDetails);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
      this.markersOn = this.mapDetails.length > 0;
      this.focusImageOnMap();
    },
    updateImage(updatedImage) {
      const updatedMapDetails = this.mapDetails.map((mapDetail) => {
        if (mapDetail.metadataId === updatedImage.filename) {
          return {
            metadataId: updatedImage.filename,
            classes: updatedImage.process_tracking.slice(-1)[0].labels,
            createdAt: updatedImage.date,
            location: updatedImage.location,
            severity: updatedImage.process_tracking.slice(-1)[0].severity,
            caption: updatedImage.caption,
          };
        }
        return mapDetail;
      });
      this.setMarkers(updatedMapDetails);
      this.setHeatMap(updatedMapDetails);
    },
    filterSeverity(severity) {
      let updatedMapDetails = this.mapDetails;
      if (severity) {
        updatedMapDetails = updatedMapDetails.filter((md) => md.severity === severity);
      }
      this.setMarkers(updatedMapDetails);
      this.setHeatMap(updatedMapDetails);
    },
    focusImageOnMap() {
      let lat;
      let lng;

      if (getImage()) {
        const [current] = this.allImages.filter((img) => img.filename === getImage());
        [lat, lng] = current.location;

        const pos = new this.google.maps.LatLng(lat, lng);

        const marker = this.markers.find((m) => {
          const lats = m.getPosition().lat() === pos.lat();
          const lngs = m.getPosition().lng() === pos.lng();
          return lats && lngs;
        });

        this.map.panTo(pos);

        // user clicks...
        // this triggers a click...
        this.google.maps.event.trigger(marker, 'click');
        this.map.setZoom(16);
      } else if (this.current !== '') {
        [lat, lng] = this.currentImage.location;
        const pos = new this.google.maps.LatLng(lat, lng);

        const marker = this.markers.find((m) => {
          const lats = m.getPosition().lat() === pos.lat();
          const lngs = m.getPosition().lng() === pos.lng();
          return lats && lngs;
        });

        this.map.panTo(pos);

        // user clicks...
        // this triggers a click...
        this.google.maps.event.trigger(marker, 'click');
        this.map.setZoom(16);
      }
    },
  },
  computed: {
    ...mapGetters(['allImages', 'currentImage', 'companyHas', 'currentFolder', 'allFolders', 'insightsFaults']),
    mapDetails() {
      return this.imagesWithLocation.map((image) => ({
        metadataId: image.filename,
        classes: image.process_tracking.slice(-1)[0].labels,
        createdAt: image.date,
        location: image.location,
        severity: image.process_tracking.slice(-1)[0].severity,
        caption: image.caption,
      }));
    },
    imagesWithLocation() {
      let locImages = [];
      const imagesToUse = this.insightsFaults.length > 0 ? this.imagesFilteredByFault : this.images;
      locImages = imagesToUse.filter((img) => {
        try {
          // eslint-disable-next-line no-restricted-globals
          let invalidLocation = isNaN(img.location[0]) || isNaN(img.location[1]);
          invalidLocation = invalidLocation || img.location === undefined;
          invalidLocation = invalidLocation || img.location.length === 0;
          invalidLocation = invalidLocation || img.location[0] === null;
          invalidLocation = invalidLocation || img.location[0] === null;
          if (invalidLocation) return false;
        } catch (e) {
          return false;
        }
        return true;
      });
      return locImages.filter((image) => image.location[0] !== null && image.location[1] !== null);
    },
  },
  async mounted() {
    await this.initMap();
    this.imagesFilteredByFault = this.images;
  },
  watch: {
    mapDetails(newImages, oldImages) {
      const newFilenames = newImages.map((image) => image.metadataId);
      const oldFilenames = oldImages.map((image) => image.metadataId);

      const newSeverities = newImages.map((image) => image.severity);
      const oldSeverities = oldImages.map((image) => image.severity);

      const imagesMatch = this.isEqual(newFilenames, oldFilenames);
      const severitiesMatch = this.isEqual(newSeverities, oldSeverities);

      if (!imagesMatch || !severitiesMatch) {
        this.setMarkers(newImages);
        this.setHeatMap(newImages);
        this.focusImageOnMap();
      }
    },
    currentImage(newImage, oldImage) {
      if (newImage.folder) {
        if (newImage.folder !== this.currentFolder.path) {
          const [currentFolder] = this.allFolders
            .filter((folder) => folder.path === newImage.folder);
          this.setCurrentFolder(currentFolder);
        }
      }

      const newSeverity = newImage.process_tracking.slice(-1)[0].severity;
      const oldSeverity = oldImage.process_tracking.slice(-1)[0].severity;

      if (newSeverity !== oldSeverity) {
        this.setMarkers(this.mapDetails);
        this.setHeatMap(this.mapDetails);
      }

      this.focusImageOnMap();
    },
    /**
     * This watches the insightsFaults property which contains
     * a list of faults that the user has selected.
     * When the insightsFaults property changes,
     * it filters the images and returns those which contain the selected faults and
     * updates the state method "imagesFilteredByFault" which is used to display the markers
     */
    insightsFaults(newFaults) {
      const filteredImages = [];
      this.images.forEach((image) => {
        let imageLabels = [];
        image.process_tracking.forEach((imageProcessTracking) => {
          imageLabels = [...new Set([...imageLabels, ...imageProcessTracking.labels])];
        });
        imageLabels.forEach((imageLabel) => {
          if (newFaults.includes(imageLabel)) {
            filteredImages.push(image);
          }
        });
      });

      this.imagesFilteredByFault = filteredImages;
    },
  },
  destroyed() {
    const currentImage = sessionStorage.getItem('currentImage');
    if (currentImage) sessionStorage.clear();
  },
};
</script>

<style scoped>
  .map{ width: 100%; height: 50vh; }
</style>
