<template>
  <div id="severity-level-filters" class="mx-auto d-block">
    <v-chip-group active-class="primary--text" v-model="selected">
      <v-chip
        v-for="(severity, i) in severities"
        :key="severity"
        small
        :color="colors[i]"
      >
        {{ severity }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import severityColorPicker from '@components/images/severity';

export default {
  name: 'SeverityLevelFilters',
  data: () => ({
    defaultSeverities: ['None', 'Low', 'Medium', 'High'],
    tdScale: ['None', 'TD-1', 'TD-2', 'TD-4', 'TD-5'],
    colors: ['', '', '', '', '', ''],
    selected: '',
  }),
  methods: {
    ...mapActions(['setSeverityFilterClicked', 'setSeverityFilter', 'setInsightFilters']),
    severityColor(s) {
      return severityColorPicker(s, this.companyHas('td_scaling'));
    },
  },
  computed: {
    ...mapGetters(['companyHas', 'currentSeverityFilters']),
    severities() {
      return (this.companyHas('td_scale')) ? this.tdScale : this.defaultSeverities;
    },
  },
  watch: {
    selected(newSelected) {
      const index = newSelected;
      const removeIndex = this.colors.findIndex((color) => color !== '');

      if (removeIndex !== -1) this.colors[removeIndex] = '';
      const level = (this.companyHas('td_scale')) ? this.tdScale[index] : this.defaultSeverities[index];
      this.colors[index] = severityColorPicker(level, this.companyHas('td_scale'));

      const filter = (this.companyHas('td_scale')) ? this.tdScale[index] : this.defaultSeverities[index];
      this.setSeverityFilter([filter]);
      this.setInsightFilters(typeof filter !== 'undefined' ? [filter] : []);
      this.$eventBus.$emit('map:filterSeverity', filter);
      this.setSeverityFilterClicked(true);
    },
  },
};
</script>
